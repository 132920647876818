import axios from "axios";

const addFlagAttribute = (VIN, inquireVehicleAPI, moneyOwingAPI, claimID, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}addFlagAttribute`,
    { VIN, inquireVehicleAPI, moneyOwingAPI, claimID },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};

const getalloutage = (token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}get-outage`,{},
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};

const createOutage = (api, type, start, end, status, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}create-outage`,
    { api, type, start, end, status },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};

const updateOutage = (api, type, start, end, status, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}update-outage`,
    { api, type, start, end, status },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};

export { addFlagAttribute, getalloutage, updateOutage, createOutage };