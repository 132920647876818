import axios from "axios";

const getUserDetails = (email, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}getUserDetails`,
    { email },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    });
};

const updateUserStatus = (userID, userStatus, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}updateUserStatus`,
    { userID, userStatus },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};


export { getUserDetails, updateUserStatus };