import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { UserContext } from '../../contexts/UserContext';
import { addDealer } from '../../services/DealerService';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';

const DealerModel = ({ closeModel, setDealerData }) => {

  const { token } = useContext(UserContext);
  const [show, setShow] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [traderNumber, setTraderNumber] = useState('');
  var traderRegex = /^[A-Z]\d{6}$/;

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  const handleSubmit = async () => {

    if (!traderRegex.test(traderNumber)) {
      showDefaultAlert('error', 'Error', 'Invalid trader number');
      return;
    }

    const confirmationMessage = 'Are you sure you want to add the new Dealer member?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await addDealer(companyName, traderNumber, token);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', `Successfully Added the Dealer.`);
            setDealerData((prevDealerData) => {
              return [...prevDealerData, response.data];
            });
          }
          handleClose();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            if (error.response.data === "This Dealer company exists.") {
              showDefaultAlert('error', 'Error', 'This Dealer company exists.');
            }
            else if (error.response.data === "Invalid request parameters") {
              showDefaultAlert('error', 'Error', 'Invalid request parameters');
            } else {
              showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
            }
          } else {
            showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          }
          handleClose();
        }
      },
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>Add Dealer</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: 'auto' }}>
          <Form>
            <Form.Group className="mb- d-flex" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black' }} >Company Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Company Name" onChange={(e) => setCompanyName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb- d-flex" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black' }} >Trader Number</Form.Label>
              <Form.Control type="text" placeholder="EnterTrader Number" onChange={(e) => setTraderNumber(e.target.value)} />
            </Form.Group>
            {/* <Form.Group className="mb- d-flex" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black' }} >Logo URL</Form.Label>
              <Form.Control type="text" placeholder="Enter Logo URL" onChange={(e) => setLogoURL(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb- d-flex" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black' }} >Trading Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Trading Name" onChange={(e) => setTradingName(e.target.value)} />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add Dealer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DealerModel;