import axios from 'axios';

const getClaims = (searchTerm, token) => {
  return axios.get(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}claims/${searchTerm}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateClaims = (claimID, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}updateClaim`,
    { claimID },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export { getClaims, updateClaims };
