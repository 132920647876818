import axios from 'axios';

const getLists = (searchTerm, token) => {
	return axios.get(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}listedVehicles/${searchTerm}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export { getLists };
