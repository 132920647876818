import React from 'react';
import '../assets/css/Home.css';

export default function Home() {

  return (
    <>
      <h1>Home Content</h1>
    </>
  );
}
