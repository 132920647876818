import axios from 'axios';

export const getPasses = (searchTerm, token) => {
	return axios.get(
		`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}passes/${searchTerm}`,
		{
			headers: { Authorization: `Bearer ${token}`, },
		}
	);
};

export const updatePass = (userID, CreatedAt, AvailablePasses, usedPasses, status, expiresAt, token) => {
	return axios.patch(
		`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}passes`,
		{ userID, CreatedAt, AvailablePasses, usedPasses, status, expiresAt },
		{
			headers: { Authorization: `Bearer ${token}`, },
		}
	);
};

export const createUserPass = (email, pricingName, numberOfPasses, claimValidity, passValidity, token) => {
	return axios.post(
		`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}passes`,
		{ email, pricingName, numberOfPasses, claimValidity, passValidity },
		{
			headers: { Authorization: `Bearer ${token}`, },
		}
	);
};
