import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserContext } from "../contexts/UserContext";
import { Dropdown } from "react-bootstrap";
import { formatDateToCustomString, utcTimestampToNZTimezone } from "../utils/format";
import { createPromotion, updatePromotion } from "../services/PromotionService";
import { showConfirmationAlert, showDefaultAlert } from "../utils/alerts";

const PromotionModel = ({
  closeModel,
  selectedRow,
  mode,
  setAddedPromotion,
  setUpdatedPromotion,
}) => {
  const { token } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [promotionID, setPromotionID] = useState("");
  const [promotionName, setPromotionName] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [newStart, setNewStart] = useState("");
  const [newEnd, setNewEnd] = useState("");
  const [status, setStatus] = useState("");
  const [totalPasses, setTotalPasses] = useState("");
  const [availablePasses, setAvailablePasses] = useState(null);
  const [maxPassesPerUser, setMaxPassesPerUser] = useState("");
  const [claimValidity, setClaimValidity] = useState("");
  const [passValidity, setPassValidity] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [maxPassesError, setMaxPassesError] = useState(false);
  const [invaliednumber, setInvaliednumber] = useState(false);
  const [invalidEndDate, setInvalidEndDate] = useState(false);
  const [invalidStartDate, setInvalidStartDate] = useState(false);
  const [invalidAvailablePasses, setInvalidAvailablePasses] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    if (
      !promotionName ||
      start === null ||
      end === null ||
      !totalPasses ||
      maxPassesPerUser === null ||
      availablePasses === null ||
      !claimValidity ||
      !passValidity ||
      !status
    ) {
      setValidationError(true);
      return false;
    }
    if (maxPassesPerUser > totalPasses || maxPassesPerUser > availablePasses) {
      setMaxPassesError(true);
      return false;
    }
    if (
      totalPasses < 0 ||
      maxPassesPerUser < 0 ||
      claimValidity < 0 ||
      availablePasses < 0 ||
      passValidity < 0
    ) {
      setInvaliednumber(true);
      return false;
    }
    if (availablePasses > totalPasses) {
      setInvalidAvailablePasses(true);
      return false;
    } else {
      setValidationError(false);
      return true;
    }
  };

  useEffect(() => {
    setShow(true);
    if (mode === "update") {
      setStart(new Date(utcTimestampToNZTimezone(selectedRow?.StartTime)));
      setEnd(new Date(utcTimestampToNZTimezone(selectedRow?.EndTime)));
      setNewStart(utcTimestampToNZTimezone(selectedRow?.StartTime));
      setNewEnd(utcTimestampToNZTimezone(selectedRow?.EndTime));
      setPromotionID(selectedRow?.PromotionID);
      setPromotionName(selectedRow?.PromotionName);
      setStatus(selectedRow?.Status);
      setTotalPasses(selectedRow?.TotalPasses);
      setAvailablePasses(selectedRow?.AvailablePasses);
      setMaxPassesPerUser(selectedRow?.MaxPassesPerUser);
      setClaimValidity(selectedRow?.ClaimValidity);
      setPassValidity(selectedRow?.PassValidity);
    }
  }, [mode, selectedRow]);

  const handleSubmit = async () => {
    if (!validateForm() || isSubmitting) {
      return;
    }
    const confirmationMessage =
      mode === "add"
        ? "Are you sure you want to add this promotion?"
        : "Are you sure you want to update this promotion?";


    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        setIsSubmitting(true);
        try {
          if (mode === "add") {
            const response = await createPromotion(
              newStart,
              promotionName,
              newEnd,
              status,
              totalPasses,
              availablePasses,
              maxPassesPerUser,
              claimValidity,
              passValidity,
              token
            );
            if (response.status === 200) {
              setAddedPromotion(response.data);
              showDefaultAlert(
                "success",
                "Success",
                `Successfully Added the New Promotion.`
              );
            }
          } else if (mode === "update") {

            const response = await updatePromotion(
              promotionID,
              promotionName,
              newStart,
              newEnd,
              status,
              totalPasses,
              availablePasses,
              maxPassesPerUser,
              claimValidity,
              passValidity,
              token
            );
            if (response.status === 200) {
              setUpdatedPromotion(response.data);
              showDefaultAlert(
                "success",
                "Success",
                `Successfully Updated the Promotion.`
              );
            }
          }
          handleClose();
        } catch (error) {
          setIsSubmitting(false);
          showDefaultAlert(
            "error",
            "Error",
            "An error occurred. Please try again later."
          );
          handleClose();
        }
      },
    });
  };

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  const handleAPIDropDown = (eventKey) => {
    setStatus(eventKey);
    setValidationError(false);
  };

  const handleStartDateChange = (date) => {
    setValidationError(false);
    if (end && end < date) {
      setInvalidStartDate(true);
      return;
    } else {
      setInvalidStartDate(false);
      setInvalidEndDate(false);
      const formattedDate = formatDateToCustomString(date);
      setStart(date);
      setNewStart(formattedDate);
    };
  };

  const handleEndDateChange = (date) => {
    setValidationError(false);
    if (start && start > date) {
      setInvalidEndDate(true);
      return;
    } else {
      setInvalidEndDate(false);
      setInvalidStartDate(false);
      const formattedDate = formatDateToCustomString(date);
      setEnd(date);
      setNewEnd(formattedDate);
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === "add" ? "Add Promotion" : "Update Promotion"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ margin: "auto" }}>
        <Form>
          <Form.Group
            className="mb- d-flex"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label style={{ color: "black" }}>Promotion Name</Form.Label>
            {mode === "update" ? (
              <Form.Control
                type="text"
                value={promotionName}
                onChange={(e) => {
                  setPromotionName(e.target.value);
                  setValidationError(false);
                }}
              />
            ) : (
              <Form.Control
                type="text"
                placeholder="Enter Promotion Name"
                onChange={(e) => {
                  setPromotionName(e.target.value);
                  setValidationError(false);
                }}
              />
            )}
          </Form.Group>
          <Form.Group
            className="mb- d-flex"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label style={{ color: "black" }}>Start Time</Form.Label>
            <div className="d-flex">
              <DatePicker
                selected={start}
                placeholderText={mode === "add" ? "Select Start Time" : start}
                onChange={handleStartDateChange}
                dateFormat="MMMM d, yyyy"
                className="custom-datepicker-input"
                minDate={new Date()}
              />
            </div>
          </Form.Group>
          {invalidStartDate && (
            <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              Start Date cannot be after End Date.
            </Form.Text>
          )}
          <Form.Group
            className="mb-3 d-flex"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label style={{ color: "black" }}>End Time</Form.Label>
            <div className="d-flex">
              <DatePicker
                selected={end}
                placeholderText={mode === "add" ? "Select End Time" : end}
                onChange={handleEndDateChange}
                dateFormat="MMMM d, yyyy"
                className="custom-datepicker-input"
                minDate={new Date()}
              />
            </div>
          </Form.Group>
          {invalidEndDate && (
            <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              End Date cannot be before Start Date.
            </Form.Text>
          )}
          <Form.Group
            className="mb-3 d-flex"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label style={{ color: "black", width: "150px" }}>
              Select Status
            </Form.Label>
            <Dropdown data-bs-theme="light" onSelect={handleAPIDropDown}>
              <Dropdown.Toggle style={{ color: "black" }}>
                {status === "" ? "Select Status" : status}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                <Dropdown.Item eventKey="Inactive">Inactive</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group
            className="mb-3 d-flex"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label style={{ color: "black" }}>Total Passes</Form.Label>
            {mode === "update" ? (
              <Form.Control
                type="number"
                value={totalPasses}
                onChange={(e) => {
                  setTotalPasses(parseInt(e.target.value));
                  setValidationError(false);
                  setInvaliednumber(false);
                  setInvalidAvailablePasses(false);
                  setMaxPassesError(false);
                }
                }
              />
            ) : (
              <Form.Control
                type="number"
                placeholder="Enter total passes"
                onChange={(e) => {
                  setTotalPasses(parseInt(e.target.value));
                  setAvailablePasses(parseInt(e.target.value));
                  setValidationError(false);
                  setInvaliednumber(false);
                  setInvalidAvailablePasses(false);
                  setMaxPassesError(false);
                }}
              />
            )}
          </Form.Group>
          {mode === "update" && (
            <Form.Group
              className="mb-3 d-flex"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Label style={{ color: "black" }}>
                Available Passes
              </Form.Label>

              <Form.Control
                type="number"
                value={availablePasses}
                onChange={(e) => {
                  setAvailablePasses(parseInt(e.target.value));
                  setValidationError(false);
                  setInvaliednumber(false);
                  setInvalidAvailablePasses(false);
                  setMaxPassesError(false);
                }}
              />
            </Form.Group>
          )}
          {invalidAvailablePasses && (
            <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              Available Passes cannot be greater than Total Passes.&nbsp;
            </Form.Text>
          )}
          <Form.Group
            className="mb-3 d-flex"
            controlId="exampleForm.ControlInput2"
          >
            <Form.Label style={{ color: "black" }}>
              Maximum Passes Per User
            </Form.Label>
            {mode === "update" ? (
              <Form.Control
                type="number"
                value={maxPassesPerUser}
                onChange={(e) => {
                  setMaxPassesPerUser(parseInt(e.target.value));
                  setMaxPassesError(false);
                  setValidationError(false);
                  setInvaliednumber(false);
                }}
              />
            ) : (
              <Form.Control
                type="number"
                placeholder="Enter Maximum Passes Per User"
                onChange={(e) => {
                  setMaxPassesPerUser(parseInt(e.target.value));
                  setMaxPassesError(false);
                  setValidationError(false);
                  setInvaliednumber(false);
                }}
              />
            )}
          </Form.Group>
          {maxPassesError && (
          <Form.Group>
            { mode === "update" ? (
            <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              Maximum Passes Per User cannot be greater than Available Passes.&nbsp;
            </Form.Text>
            ) : (
              <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              Maximum Passes Per User cannot be greater than Total Passes.&nbsp;
            </Form.Text>
          )} 
          </Form.Group>
          )}        
          <Form.Group
            className="mb-3 d-flex"
            controlId="exampleForm.ControlInput2"
          >
            <Form.Label style={{ color: "black" }}>Claim Validity</Form.Label>
            {mode === "update" ? (
              <Form.Control
                type="number"
                value={claimValidity}
                onChange={(e) => {
                  setClaimValidity(parseInt(e.target.value));
                  setValidationError(false);
                  setInvaliednumber(false);
                }}
              />
            ) : (
              <Form.Control
                type="number"
                placeholder="Enter Claim Validity"
                onChange={(e) => {
                  setClaimValidity(parseInt(e.target.value));
                  setValidationError(false);
                  setInvaliednumber(false);
                }}
              />
            )}
          </Form.Group>
          <Form.Group
            className="mb-3 d-flex"
            controlId="exampleForm.ControlInput2"
          >
            <Form.Label style={{ color: "black" }}>Pass Validity</Form.Label>
            {mode === "update" ? (
              <Form.Control
                type="number"
                value={passValidity}
                onChange={(e) => {
                  setPassValidity(parseInt(e.target.value));
                  setValidationError(false);
                  setInvaliednumber(false);
                }} />
            ) : (
              <Form.Control
                type="number"
                placeholder="Enter Pass Validity"
                onChange={(e) => {
                  setPassValidity(parseInt(e.target.value));
                  setValidationError(false);
                  setInvaliednumber(false);
                }}
              />
            )}
          </Form.Group>
          {invaliednumber && (
            <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              Invalid number.&nbsp;
            </Form.Text>
          )}
          {validationError && (
            <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              All fields are required.&nbsp;
            </Form.Text>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
          {mode === "add" ? "Add Promotion" : "Update Promotion"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PromotionModel;
