export const Passes = [
	{
		id: "single-pass",
		pricingName: "Single Pass",
		numberOfPasses: 1,
		claimValidity: 120,
		passValidity: 365,
	},
	{
		id: "bundale-pass",
		pricingName: "Bundle Pass",
		numberOfPasses: 5,
		claimValidity: 150,
		passValidity: 365,
	},
]



