import axios from "axios";

const getlistedVehicles = (token) => {
  return axios.post(`${ process.env.REACT_APP_OPERATOR_API_GATEWAY_URL }getListRecords`,{},
    {headers: 
      {Authorization: `Bearer ${token}`},
    }
  );
};

const updateListRecord = (claimID, status, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}updateListRecord`, 
  { claimID, status },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};

export { getlistedVehicles, updateListRecord };