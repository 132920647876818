import React, { useContext, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Button } from 'react-bootstrap';
import { TimestampToDate } from '../../utils/format';
import PassModel from './PassModel';
import AddPassModel from './AddPassModel';
import { UserContext } from '../../contexts/UserContext';

export const PassesTable = (props) => {

  const { searchResults, searchTerm } = props;
  const { personalPasses, dealerPasses } = searchResults.data;

  const { token } = useContext(UserContext);

  const [expiredPersonalPasses, setExpiredPersonalPasses] = useState([]);
  const [usedPersonalPasses, setUsedPersonalPasses] = useState([]);
  const [availablePersonalPasses, setAvailablePersonalPasses] = useState([]);
  const [expiredDealerPasses, setExpiredDealerPasses] = useState([]);
  const [usedDealerPasses, setUsedDealerPasses] = useState([]);
  const [availableDealerPasses, setAvailableDealerPasses] = useState([]);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAddModel, setShowAddModel] = useState(false);
  const [newPersonalPasses, setNewPersonalPasses] = useState(personalPasses);
  const [newDealerPasses, setNewDealerPasses] = useState(dealerPasses);

  const handleUpdateModel = (availablePass) => {

    setShowUpdateModel(true);
    setSelectedRow(availablePass);
  };

  const categorizePasses = () => {
    if (newPersonalPasses.length > 0) {
      const expiredPasses = [];
      const usedPasses = [];
      const availablePasses = [];
      newPersonalPasses.forEach((payment) => {
        if (payment.Status === 'Expired') {
          expiredPasses.push(payment);
        } else if (payment.Status === 'Active' && payment.AvailablePasses === 0) {
          usedPasses.push(payment);
        } else if (payment.Status === 'Active' && payment.AvailablePasses > 0) {
          availablePasses.push(payment);
        }
      });
      setExpiredPersonalPasses(expiredPasses);
      setUsedPersonalPasses(usedPasses);
      setAvailablePersonalPasses(availablePasses);
    }
    if (newDealerPasses.length > 0) {
      const expiredPasses = [];
      const usedPasses = [];
      const availablePasses = [];
      newDealerPasses.forEach((payment) => {
        if (payment.Status === 'Expired') {
          expiredPasses.push(payment);
        } else if (payment.Status === 'Active' && payment.AvailablePasses === 0) {
          usedPasses.push(payment);
        } else if (payment.Status === 'Active' && payment.AvailablePasses > 0) {
          availablePasses.push(payment);
        }
      });
      setExpiredDealerPasses(expiredPasses);
      setUsedDealerPasses(usedPasses);
      setAvailableDealerPasses(availablePasses);
    }
  };

  const handlePassUpdate = (updatedPass) => {
    if (updatedPass.DealerID) {
      setNewDealerPasses((newDealerPasses) =>
        newDealerPasses.map((pass) =>
          pass.CreatedAt === updatedPass.CreatedAt ? updatedPass : pass
        )
      );
    }
    else {
      setNewPersonalPasses((newPersonalPasses) =>
        newPersonalPasses.map((pass) =>
          pass.CreatedAt === updatedPass.CreatedAt ? updatedPass : pass
        )
      );
    }
    setShowUpdateModel(false);
  };


  const handleUpdatedPasses = (newPass) => {
    if (newPass.DealerID) {
      setNewDealerPasses([...newDealerPasses, newPass]);
    }
    else {
      setNewPersonalPasses([...newPersonalPasses, newPass]);
    }
  };

  useEffect(() => {
    categorizePasses();
  }, [newPersonalPasses, newDealerPasses, token]);

  const handleAddPassModel = () => {
    setShowAddModel(true);
  };

  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
          <h5 className="text-muted mb-0">Add a new pass to the user</h5>
          <Button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={() => handleAddPassModel()}>Add Pass</Button>
        </div>
        <div>
          {showAddModel && <AddPassModel searchTerm={searchTerm} closeModel={setShowAddModel} newPass={handleAddPassModel} setPasses={handleUpdatedPasses} />}
        </div>
        <h3 className="claim-topic-color">Passes!</h3>
        <div className="row mb-5">
          <Accordion defaultActiveKey={['0']} alwaysOpen style={{ marginBottom: '100px' }}>
            {availablePersonalPasses && availablePersonalPasses.length > 0 && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>Available Personal Passes</Accordion.Header>
                <Accordion.Body>
                  <table className="table align-middle mb-0 mt-2 bg-white">
                    <thead className="bg-light">
                      <tr>
                        <th>Name</th>
                        <th>Available Passes</th>
                        <th>Used Passes</th>
                        <th>Expired Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {availablePersonalPasses.map((availablePass) => (
                        <tr key={availablePass.CreatedAt} >
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{availablePass.PricingName}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{availablePass.AvailablePasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{availablePass.UsedPasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{TimestampToDate(availablePass.ExpiresAt)}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <Button className="btn btn-danger" onClick={() => handleUpdateModel(availablePass)}>update</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {usedPersonalPasses && usedPersonalPasses.length > 0 && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Used Personal Passes</Accordion.Header>
                <Accordion.Body>
                  <table className="table align-middle mb-0 mt-2 bg-white">
                    <thead className="bg-light">
                      <tr>
                        <th>Name</th>
                        <th>Available Passes</th>
                        <th>Used Passes</th>
                        <th>Expired Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usedPersonalPasses.map((usedPass) => (
                        <tr key={usedPass.CreatedAt}>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{usedPass.PricingName}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{usedPass.AvailablePasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{usedPass.UsedPasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{TimestampToDate(usedPass.ExpiresAt)}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <Button className="btn btn-danger" onClick={() => handleUpdateModel(usedPass)}>update</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {expiredPersonalPasses && expiredPersonalPasses.length > 0 && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>Expired Personal Passes</Accordion.Header>
                <Accordion.Body>
                  <table className="table align-middle mb-0 mt-2 bg-white">
                    <thead className="bg-light">
                      <tr>
                        <th>Name</th>
                        <th>Available Passes</th>
                        <th>Used Passes</th>
                        <th>Expired Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expiredPersonalPasses.map((expiredPass) => (

                        <tr key={expiredPass.CreatedAt}>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{expiredPass.PricingName}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{expiredPass.AvailablePasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{expiredPass.UsedPasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{TimestampToDate(expiredPass.ExpiresAt)}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <Button className="btn btn-danger" onClick={() => handleUpdateModel(expiredPass)}>update</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
          <Accordion defaultActiveKey={['0']} alwaysOpen style={{ marginBottom: '100px' }}>
            {availableDealerPasses && availableDealerPasses.length > 0 && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>Available Dealer Passes</Accordion.Header>
                <Accordion.Body>
                  <table className="table align-middle mb-0 mt-2 bg-white">
                    <thead className="bg-light">
                      <tr>
                        <th>Name</th>
                        <th>Available Passes</th>
                        <th>Used Passes</th>
                        <th>Expired Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {availableDealerPasses.map((availablePass) => (
                        <tr key={availablePass.CreatedAt} >
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{availablePass.PricingName}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{availablePass.AvailablePasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{availablePass.UsedPasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{TimestampToDate(availablePass.ExpiresAt)}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <Button className="btn btn-danger" onClick={() => handleUpdateModel(availablePass)}>update</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {usedDealerPasses && usedDealerPasses.length > 0 && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Used Dealer Passes</Accordion.Header>
                <Accordion.Body>
                  <table className="table align-middle mb-0 mt-2 bg-white">
                    <thead className="bg-light">
                      <tr>
                        <th>Name</th>
                        <th>Available Passes</th>
                        <th>Used Passes</th>
                        <th>Expired Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usedDealerPasses.map((usedPass) => (
                        <tr key={usedPass.CreatedAt}>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{usedPass.PricingName}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{usedPass.AvailablePasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{usedPass.UsedPasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{TimestampToDate(usedPass.ExpiresAt)}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <Button className="btn btn-danger" onClick={() => handleUpdateModel(usedPass)}>update</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {expiredDealerPasses && expiredDealerPasses.length > 0 && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>Expired Dealer Passes</Accordion.Header>
                <Accordion.Body>
                  <table className="table align-middle mb-0 mt-2 bg-white">
                    <thead className="bg-light">
                      <tr>
                        <th>Name</th>
                        <th>Available Passes</th>
                        <th>Used Passes</th>
                        <th>Expired Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expiredDealerPasses.map((expiredPass) => (

                        <tr key={expiredPass.CreatedAt}>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{expiredPass.PricingName}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{expiredPass.AvailablePasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{expiredPass.UsedPasses}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <p className="text-muted mb-0">{TimestampToDate(expiredPass.ExpiresAt)}</p>
                          </td>
                          <td style={{ width: '20%' }}>
                            <Button className="btn btn-danger" onClick={() => handleUpdateModel(expiredPass)}>update</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
          <br />
          {showUpdateModel && <PassModel closeModel={setShowUpdateModel} selectedRow={selectedRow} updatedPass={handlePassUpdate} />}
        </div>
      </div>
    </div>
  );
};
