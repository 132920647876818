import React, { useContext, useEffect, useState } from 'react';
import Modal from "@mui/material/Modal";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDropzone } from 'react-dropzone';
import Cloudimg from '../assets/img/cloud.jpg';
import { addVehicleDefinition, getSignedUrl } from '../services/vehicleDefinitionServices';
import { UserContext } from '../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../utils/alerts';
import { Spinner } from '../utils/Spinner';

const years = Array.from({ length: 30 }, (_, index) => 2022 - index);

const AddCarDefinition = ({ open, handleClose, addCarDefinition, carData }) => {

  const { token } = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    textField: '',
    make: '',
    model: '',
    fuelType: '',
    year: '',
    images: [],
    toggleSwitch: false,
    radioValue: '',
  });
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [filteredMakes, setFilteredMakes] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [filteredFuelTypes, setFilteredFuelTypes] = useState([]);

  useEffect(() => {
    setMakes([...new Set(carData.map(car => car.Make))]);
    setModels([...new Set(carData.map(car => car.Model))]);
    setFuelTypes([...new Set(carData.map(car => car.FuelType))]);
  }, [carData]);

  const updateFilteredOptions = (selectedMake, selectedModel, selectedFuelType) => {
    const filteredData = carData.filter(car => {
      return (!selectedMake || car.Make === selectedMake) &&
        (!selectedModel || car.Model === selectedModel) &&
        (!selectedFuelType || car.FuelType === selectedFuelType);
    });

    setFilteredMakes([...new Set(filteredData.map(car => car.Make))]);
    setFilteredModels([...new Set(filteredData.map(car => car.Model))]);
    setFilteredFuelTypes([...new Set(filteredData.map(car => car.FuelType))]);
  };


  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (field === 'make') {
      updateFilteredOptions(value, formData.model, formData.fuelType);
    } else if (field === 'model') {
      updateFilteredOptions(formData.make, value, formData.fuelType);
    } else if (field === 'fuelType') {
      updateFilteredOptions(formData.make, formData.model, value);
    }
  };

  const handleSubmit = async () => {
    if (!formData.make || !formData.model || !formData.fuelType || !formData.year) {
      setSubmitted(true);
      return;
    } else {
      setSubmitted(true);
      handleClose();
      const confirmationMessage = 'Are you sure you want to add this vehicle definition?';
      showConfirmationAlert({
        confirmationMessage,
        onConfirm: async () => {
          try {
            setLoaded(true);
            const errors = [];
            if (photos && photos.length > 0) {
              await Promise.all(photos.map(async (selectedFile) => {
                try {
                  const action = 'upload';
                  const file = selectedFile.file;
                  const imageResponse = await getSignedUrl('', file, action, token);
                  const { signedUrl, imageID } = imageResponse.data;

                  const imageUploadResult = await fetch(signedUrl, {
                    method: 'PUT',
                    body: file,
                  });
                  if (imageUploadResult.ok) {
                    const urlObject = new URL(imageUploadResult.url);
                    const ImageURL = urlObject.origin + urlObject.pathname;

                    formData.images.push({ ImageURL, ImageID: imageID });
                  } else {
                    throw new Error('Failed to upload image');
                  }
                } catch (error) {
                  errors.push(error.message);
                }
              }));
            }

            const response = await addVehicleDefinition(
              formData.make,
              formData.model,
              formData.fuelType,
              formData.year,
              formData.images,
              token
            );
            setLoaded(false);
            if (response.status === 200) {
              addCarDefinition(response.data);
              showDefaultAlert('success', 'Success', 'Successfully added vehicle definition');
              resetForm();
            }
          } catch (error) {
            showDefaultAlert('error', 'Error', 'Failed to add vehicle definition');
            resetForm();
          } finally {
            setLoaded(false);
            handleClose();
          }
        },
        onCancel: () => {
          resetForm();
        },
      });
    }
  };

  const resetForm = () => {
    setFormData({
      make: '',
      model: '',
      fuelType: '',
      year: '',
      images: [],
    });
    setPhotos([]);
    setSubmitted(false);
  };

  const onDrop = (acceptedFiles) => {
    // Handle dropped files
    const newPhotos = acceptedFiles.map((file) => ({
      id: Math.random().toString(36).substring(7),
      file,
    }));

    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
  };

  const removePhoto = (id) => {
    setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== id));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const handleModalClose = () => {
    handleClose(false);
    setFormData({
      make: '',
      model: '',
      fuelType: '',
      year: '',
      images: [],
    });
    setPhotos([]);
    setSubmitted(false);
  };

  return (
    <>
      {loaded ? (<div className="middle" > <Spinner></Spinner></div>) : (

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ width: '80%', margin: 'auto' }}
        >
          <Card style={{ maxWidth: '100vw', margin: 'auto', marginTop: 20 }}>
            <CardContent>
              <form>
                <div className="col-12 col-sm-12 col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3 col-md-3">
                      <Autocomplete
                        freeSolo
                        options={filteredMakes.length > 0 ? filteredMakes : makes}
                        getOptionLabel={(option) => option}
                        value={formData.make}
                        onChange={(_, newValue) => handleChange('make', newValue)}
                        onInputChange={(_, newInputValue) => handleChange('make', newInputValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Make"
                            variant="outlined"
                            fullWidth
                            required
                            error={submitted && !formData.make}
                            helperText={submitted && !formData.make && '*required'}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 col-md-3">
                      <Autocomplete
                        options={filteredModels.length > 0 ? filteredModels : models}
                        getOptionLabel={(option) => option}
                        freeSolo
                        value={formData.model}
                        onInputChange={(_, newInputValue) => handleChange('model', newInputValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Model"
                            variant="outlined"
                            required
                            error={submitted && !formData.model}
                            helperText={submitted && !formData.model && '*required'}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 col-md-3">
                      <Autocomplete
                        options={filteredFuelTypes.length > 0 ? filteredFuelTypes : fuelTypes}
                        getOptionLabel={(option) => option}
                        value={formData.fuelType}
                        onInputChange={(_, newValue) => handleChange('fuelType', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Fuel Type"
                            variant="outlined"
                            fullWidth
                            required
                            error={submitted && !formData.fuelType}
                            helperText={submitted && !formData.fuelType && '*required'}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 col-md-3">
                      <Autocomplete
                        options={years}
                        getOptionLabel={(option) => option.toString()}
                        value={formData.year}
                        onChange={(_, newValue) => handleChange('year', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Year"
                            variant="outlined"
                            fullWidth
                            required
                            error={submitted && !formData.year}
                            helperText={submitted && !formData.year && '*required'}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ margin: '15px' }}>
                  <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p style={{ color: 'black', margin: '0px' }}>Drag & drop images here, or click to browse</p>
                    <img
                      src={Cloudimg}
                      alt="Cloud Icon"
                      style={{ width: '100px', height: '80px', margin: '20px' }}
                    />
                  </div>

                  <div style={{ marginTop: '20px' }}>
                    {photos.map((photo) => (
                      <div key={photo.id} style={photoContainerStyles}>
                        <img src={URL.createObjectURL(photo.file)} alt="Uploaded" style={photoStyles} />
                        <button onClick={() => removePhoto(photo.id)}>Remove</button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* <div>
                  <TextField
                    label="Add Models"
                    variant="outlined"
                    value={inputValue}
                    onChange={handleInputChange}
                    fullWidth
                  />
                  <Box mt={2}>
                    <Button
                      variant="outlined" onClick={handleAddTag}
                      style={{ borderColor: 'black', color: 'black', marginTop: 16, width: '120px', margin: '12px' }}>
                      Add Model
                    </Button>
                    <div>
                      {tags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          onDelete={() => handleRemoveTag(index)}
                          style={{ margin: '4px' }}
                        />
                      ))}
                    </div>
                  </Box>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'end' }}>

                  <Button
                    variant="contained"
                    onClick={handleModalClose}
                    style={{ backgroundColor: 'black', color: 'white', marginTop: 16, width: '50px', margin: '12px' }}
                  >
                    Close
                  </Button>

                  <Button
                    variant="contained"
                    type='button'
                    style={{ backgroundColor: 'black', color: 'white', marginTop: 16, width: '50px', margin: '12px' }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Modal>
      )}
    </>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

const photoContainerStyles = {
  display: 'inline-block',
  marginRight: '10px',
};

const photoStyles = {
  width: '100px',
  height: '100px',
  objectFit: 'cover',
  borderRadius: '4px',
};

export default AddCarDefinition;
