import axios from "axios";

const getLimitations = (email, API, token) => {
    return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}getAPILimit`,
        { email, API },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

const updateLimitations = (userID, API, dailyStart, dailyUsage, hourStart, hourlyUsage, monthlyStart, monthlyUsage, token) => {
    return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}updateAPILimit`,
        { userID, API, dailyStart, dailyUsage, hourStart, hourlyUsage, monthlyStart, monthlyUsage },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
};

export { getLimitations, updateLimitations };