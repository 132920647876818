import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import { utcTimestampToNZTimezone } from '../utils/format';
import SpinnerA from '../components/Spinner';
import ReactPaginate from 'react-paginate';
import '../App.css';
import FeaturedDealerModel from '../components/dealer/FeaturedDealerModel';
import { deleteFeaturedDealer, getAllFeaturedDealers } from '../services/DealerService';
import { showConfirmationAlert, showDefaultAlert } from '../utils/alerts';

export const FeaturedDealer = () => {

  const { token } = useContext(UserContext);
  const [featuredDealerData, setFeaturedDealerData] = useState([]);
  const [updatedDealer, setUpdatedDealer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [showFeaturedDealerModel, setShowFeaturedDealerModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dealerID, setDealerID] = useState('');
  const [mode, setMode] = useState('');

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;
  const currentPageItems = featuredDealerData?.slice(offset, offset + itemsPerPage);

  const handleUpdateModel = (featuredDealer) => {
    setShowFeaturedDealerModel(true);
    setDealerID(featuredDealer.DealerID);
    setMode('update');
    setSelectedRow(featuredDealer);
  };

  const sortedData = (data) => {
    return data.sort((a, b) => {
      if (a.Status === 'Active') return -1;
      if (b.Status === 'Active') return 1;
      return 0;
    });
  };

  useEffect(() => {
    if (updatedDealer) {
      const updatedFeaturedDealerData = featuredDealerData.map((featuredDealer) => {
        if (featuredDealer.DealerID === updatedDealer.DealerID) {
          return {
            ...featuredDealer,
            StartTime: updatedDealer.StartTime,
            EndTime: updatedDealer.EndTime,
            Status: updatedDealer.Status,
          };
        }
        return featuredDealer;
      });
      setFeaturedDealerData(sortedData(updatedFeaturedDealerData));
    } else {
      setFeaturedDealerData(prevFeaturedDealerData => prevFeaturedDealerData.filter(dealer => dealer.DealerID !== dealerID));
    }

  }, [updatedDealer, dealerID]);

  useEffect(() => {
    setLoading(true);
    const fetchVehicleRecords = async () => {
      try {
        const response = await getAllFeaturedDealers(token);
        if (response.status === 200) {
          setFeaturedDealerData(sortedData(response.data.dealerDetails));
        }
      } catch (error) {
        console.error('Error fetching dealer records:', error);
      }
      setLoading(false);
    };
    fetchVehicleRecords();
  }, [token]);

  const handleDelete = async (dealerID) => {
    const confirmationMessage = 'Are you sure you want to delete the Featured Dealer?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await deleteFeaturedDealer(dealerID, token);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', `Successfully Deleted the Featured Dealer.`);
            setFeaturedDealerData(featuredDealerData.filter(dealer => dealer.DealerID !== dealerID));
          }
        }
        catch (error) {
          showDefaultAlert('error', 'Error', `Error Deleting the Featured Dealer.`);
        }
      }
    });
  };
  return (
    <div style={{ width: "90%", margin: "auto", marginTop: "50px" }}>
      <div>
        <h3 className="claim-topic-color">Featured Dealers</h3>
      </div>
      {loading ? (<SpinnerA />) : (<div>
        <Table responsive="sm" hover >
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {featuredDealerData.length === 0 ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <h5> No records found.</h5>
                </td>
              </tr>
            ) : (
              <React.Fragment>
                {currentPageItems.map((featuredDealer) => (
                  <tr
                    key={featuredDealer.DealerID}
                    className={featuredDealer.Status === 'Active' ? "table-primary" : ''}
                  >
                    <td>{featuredDealer.DealerDetails.CompanyName}</td>
                    <td>{utcTimestampToNZTimezone(featuredDealer.StartTime)}</td>
                    <td>{utcTimestampToNZTimezone(featuredDealer.EndTime)}</td>
                    <td>{featuredDealer.Status}</td>
                    <td>
                      <Button className="btn btn-danger" style={{ width: 'auto', marginInline: '5px' }} onClick={() => handleUpdateModel(featuredDealer)}>Update</Button>
                      <Button className="btn btn-danger" style={{ width: 'auto', marginInline: '5px' }} onClick={() => handleDelete(featuredDealer.DealerID)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </Table>

        {showFeaturedDealerModel && <FeaturedDealerModel closeModel={setShowFeaturedDealerModel} dealerID={dealerID} mode={mode} selectedRow={selectedRow} setUpdateDealer={setUpdatedDealer} />}

        {featuredDealerData && featuredDealerData.length > itemsPerPage && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={Math.ceil(featuredDealerData.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(data) => setCurrentPage(data.selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousClassName={'pagination-previous'}
            nextClassName={'pagination-next'}
            pageClassName={'pagination-page'}
            breakClassName={'pagination-break'}
            previousLinkClassName={'pagination-previous-link'}
            nextLinkClassName={'pagination-next-link'}
            disabledClassName={'pagination-disabled'}
          />
        )}
      </div>)
      }
    </div>
  );
};
