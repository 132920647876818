import { createContext } from 'react';

export const UserContext = createContext();

export const UserProvider = (props) => {

  // Render the provider component with the user ID value and setter function in the context
  return (
    <UserContext.Provider value={props.value}>
      {props.children}
    </UserContext.Provider>
  );
};
