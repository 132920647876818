import React, { useContext, useEffect, useState } from 'react';
import '../assets/css/VehicleDefinition.css';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../contexts/UserContext';
import { deleteVehicleDefinition, getAllVehicleDefinitions, getSignedUrl } from '../services/vehicleDefinitionServices';
import { showConfirmationAlert, showDefaultAlert } from '../utils/alerts';
import UpdateCarDefinition from './UpdateCarDefinition';
import AddCarDefinition from './AddCarDefinition';
import { Spinner } from '../utils/Spinner';

const ImageUpload = () => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const { token } = useContext(UserContext);
  const [carData, setCarData] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [updatedRecord, setUpdatedRecord] = useState(null);
  const [carDefinition, setCarDefinition] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleOpenUpdateModal = (carDataRecord) => {
    setSelectedRecord(carDataRecord || {});
    setIsUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAllVehicleDefinitions(token);
        setCarData(response.data);
        setLoading(false);
      } catch (error) {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const handleDelete = async (carDataRow) => {
    const confirmationMessage = 'Are you sure you want to delete this vehicle definition?';
    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        setLoading(true);
        try {
          const action = 'delete';
          const imageIDs = carDataRow.Images.map((image) => image.ImageID);
          const vehicleID = carDataRow.VehicleID;
          const imageDeletePromises = imageIDs.map(async (imageID) => {
            try {
              const imageResponse = await getSignedUrl(imageID, '', action, token);
              const { signedUrl } = imageResponse.data;
              const imageDeleteResult = await fetch(signedUrl, {
                method: 'DELETE',
              });
              if (imageDeleteResult.ok) {
                return imageDeleteResult.ok;
              }
            } catch (error) {

            }
          });

          const imageDeleteResults = await Promise.all(imageDeletePromises);

          if (!imageDeleteResults.every((result) => result)) {
            setLoading(false);
            showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          }

          const response = await deleteVehicleDefinition(vehicleID, token);
          setLoading(false);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', 'Successfully deleted the vehicle definition.');
            const newCarData = carData.filter((car) => car.VehicleID !== vehicleID);
            setCarData(newCarData);
          }
        } catch (error) {
          setLoading(false);
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }
      },
      onCancel: () => { },
    });
  };

  useEffect(() => {
    if (updatedRecord) {
      setCarData((prevCarData) =>
        prevCarData.map((car) =>
          car.VehicleID === updatedRecord.VehicleID ? updatedRecord : car
        )
      );
    }
  }, [updatedRecord]);

  useEffect(() => {
    if (carDefinition) {
      setCarData((prevCarData) => [...prevCarData, carDefinition]);
    }
  }, [carDefinition]);

  return (
    <div className="container py-5">
      <>
        <header className="text-white text-center">
          <h1>Vehicle Definition</h1>
        </header>
        {loading ? (<Spinner />) : (<div>
          <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddModal}
            style={{ margin: '10px', border: '2px solid' }}>
            Add
          </Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Make</StyledTableCell>
                  <StyledTableCell align="right">Model</StyledTableCell>
                  <StyledTableCell align="right">Fuel Type</StyledTableCell>
                  <StyledTableCell align="right">Year</StyledTableCell>
                  <StyledTableCell align="right">Images</StyledTableCell>
                  <StyledTableCell align="right">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carData.map((carDataRow) => (
                  <StyledTableRow key={carDataRow.make}>
                    <StyledTableCell component="th" scope="row">
                      {carDataRow.Make}
                    </StyledTableCell>
                    <StyledTableCell align="right">{carDataRow.Model}</StyledTableCell>
                    <StyledTableCell align="right">{carDataRow.FuelType}</StyledTableCell>
                    <StyledTableCell align="right">{carDataRow.YearofManufacture}</StyledTableCell>
                    <StyledTableCell align="right">
                      {carDataRow.Images && carDataRow.Images[0] && (
                        <img src={carDataRow.Images[0].ImageURL} alt="Car" style={{ width: '100px', height: 'auto' }} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <FontAwesomeIcon icon={faPencilAlt} style={{ color: 'gray', fontSize: '20px', padding: '8px', }} onClick={() => handleOpenUpdateModal(carDataRow)} />
                      <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#EC5B5B', padding: '8px', fontSize: '20px', }} onClick={() => handleDelete(carDataRow)} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AddCarDefinition open={isAddModalOpen} handleClose={handleCloseAddModal} addCarDefinition={setCarDefinition} carData={carData} />
          <UpdateCarDefinition open={isUpdateModalOpen} handleClose={handleCloseUpdateModal} carDataRecord={selectedRecord} setUpdated={setUpdatedRecord} carData={carData} />
        </div>)}
      </>
    </div>
  );
};

export default ImageUpload;
