import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { UserContext } from '../../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addFeaturedDealer, updateFeaturedDealer } from '../../services/DealerService';
import { formatDateToCustomString, utcTimestampToNZTimezone } from '../../utils/format';

const FeaturedDealerModel = ({ closeModel, dealerID, mode, selectedRow, setUpdateDealer }) => {

  const { token } = useContext(UserContext);
  const [show, setShow] = useState(true);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [invalidStartDate, setInvalidStartDate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState("");

  const validateForm = () => {
    if (start === null || end === null) {
      setValidationError(true);
      return false;
    } else if (start > end) {
      setInvalidStartDate(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (mode === 'update') {
      setStart(new Date(utcTimestampToNZTimezone(selectedRow?.StartTime)));
      setEnd(new Date(utcTimestampToNZTimezone(selectedRow?.EndTime)));
      setStartTime(utcTimestampToNZTimezone(selectedRow?.StartTime));
      setEndTime(utcTimestampToNZTimezone(selectedRow?.EndTime));
      setStatus(selectedRow?.Status);
    }
  }, [selectedRow, mode]);

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  const handleStartDateChange = (date) => {
    setValidationError(false);
    setInvalidStartDate(false);
    const formattedDate = formatDateToCustomString(date);
    setStart(date);
    setStartTime(formattedDate);
  };

  const handleEndDateChange = (date) => {
    setValidationError(false);
    setInvalidStartDate(false);
    const formattedDate = formatDateToCustomString(date);
    setEnd(date);
    setEndTime(formattedDate);
  };

  const handleSubmit = async () => {
    if (!validateForm() || isSubmitting) {
      return;
    }
    const confirmationMessage =
      mode === 'add'
        ? 'Are you sure you want to add this as a featured dealer?'
        : 'Are you sure you want to update this featured dealer?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        setIsSubmitting(true);
        try {
          if (mode === 'add') {
            const response = await addFeaturedDealer(dealerID, startTime, endTime, token);
            if (response.status === 200) {
              showDefaultAlert('success', 'Success', `Successfully Added the New Featured Dealer.`);
              handleClose();
            }
          } else if (mode === 'update') {
            const response = await updateFeaturedDealer(dealerID, startTime, endTime, status, token);
            if (response.status === 200) {
              showDefaultAlert('success', 'Success', `Successfully Updated the Featured Dealer.`);
              const updatedDealer = response.data.find(dealer => dealer.DealerID === dealerID);
              if (updatedDealer) {
                setUpdateDealer(updatedDealer);
              }
              else {
                setUpdateDealer(null);
              }
              handleClose();
            }
          }
        } catch (error) {
          setIsSubmitting(false);
          if (error.response && error.response.status === 400) {
            if (error.response.data === "A featured dealer already exists with given date and time range") {
              showDefaultAlert('error', 'Error', 'A featured dealer already exists with given date and time range');
            } else {
              showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
            }
          }
          else {
            showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          }
          handleClose();
        }
      }
    });
  };


  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>
            {mode === 'add' ? 'Make as Featured Dealer' : 'Update Featured Dealer'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: 'auto' }}>
          <Form>
            <Form.Group
              className="mb- d-flex"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label style={{ color: "black" }}>Start Time</Form.Label>
              <div className="d-flex">
                <DatePicker
                  selected={start}
                  placeholderText={mode === 'add' ? "Select Start Time" : start}
                  onChange={handleStartDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy HH:mm"
                  timeCaption="Time"
                  className="custom-datepicker-input"
                  minDate={new Date()}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3 d-flex"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Label style={{ color: "black" }}>End Time</Form.Label>
              <div className="d-flex" style={{ width: '330px' }}>
                <DatePicker
                  selected={end}
                  placeholderText={mode === 'add' ? "Select End Time" : end}
                  onChange={handleEndDateChange}
                  className="custom-datepicker-input"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy HH:mm"
                  timeCaption="Time"
                  minDate={new Date()}
                />
              </div>
            </Form.Group>
            {mode === 'update' && (
              <Form.Group
                className="mb-3 d-flex"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label style={{ color: "black" }}>Status</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ marginLeft: "10px", color: "black" }}
                  >
                    {status === "" ? "Select Status" : status}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => setStatus("Active")}
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setStatus("Inactive")}
                    >
                      Inactive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            )}
            {invalidStartDate && (
              <Form.Text style={{ color: "red", marginBottom: "10px" }}>
                Start Date cannot be after End Date.
              </Form.Text>
            )}
            {validationError && (
              <Form.Text style={{ color: "red", marginBottom: "10px" }}>
                Please fill all the fields.
              </Form.Text>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FeaturedDealerModel;