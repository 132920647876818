import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { UserContext } from '../../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';
import { addDealerAdmin } from '../../services/DealerService';

const AddAdminModel = ({ closeModel, dealerID, setUpdatedAdminData }) => {

  const { token } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  useEffect(() => {
    setShow(true);
  }, []);

  const handleSubmit = async () => {

    closeModel(false);
    const confirmationMessage = 'Are you sure you want to add New Admin?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await addDealerAdmin(email, dealerID, token);
          if (response.status === 200) {
            setUpdatedAdminData(response.data);
            showDefaultAlert('success', 'Success', 'Successfully Added the Admin.');
            setShow(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 400) {
            if (error.response.data === "Already admin for a company.") {
              showDefaultAlert('error', 'Error', 'Already admin for a company.');
            }
            else if (error.response.data === "The admin already exists.") {
              showDefaultAlert('error', 'Error', 'The admin already exists.');
            }
            else if (error.response.data === "User account has been Closed.") {
              showDefaultAlert('error', 'Error', 'User account has been Closed.');
            }
            else if (error.response.data === "User account has been Blocked.") {
              showDefaultAlert('error', 'Error', 'User account has been Blocked.');
            }
          } else {
            showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          }
        }
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>Add Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: 'auto' }}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="row">
                <div className="col-2">
                  <Form.Label style={{ color: 'black' }}>Email</Form.Label>
                </div>
                <div className="col">
                  <Form.Control type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddAdminModel;