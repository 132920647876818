import React, { useContext, useState } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../components/user/PassesTable';
import '../assets/css/User.css';
import { PassesTable } from "../components/user/PassesTable";
import { ClaimsTable } from "../components/user/ClaimsTable";
import { ListingTable } from "../components/user/ListingTable";
import { getClaims } from '../services/ClaimService';
import { getPasses } from "../services/PassesService";
import SpinnerA from "../components/Spinner";
import { getLists } from "../services/ListsServices";
import LimitationsTable from "../components/user/LimitationsTable";
import { UserContext } from '../contexts/UserContext';
import ManageUser from "../components/user/ManageUser";
import { getUserDetails } from "../services/AccountService";
import { showDefaultAlert } from "../utils/alerts";

const User = () => {

  const [passes, setPasses] = useState(false);
  const [claims, setClaims] = useState(false);
  const [listings, setListings] = useState(false);
  const [limitations, setLimitations] = useState(false);
  const [manageUser, setManageUser] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [loading, setLoading] = useState(false);

  const { token } = useContext(UserContext);

  const handlePassesSearch = async () => {

    setLoading(true);
    setClaims(false);
    setListings(false);
    setLimitations(false);
    setManageUser(false);
    try {
      const response = await getPasses(searchTerm, token);
      setSearchResults(response);
      setPasses(true);
      setLoading(false);

    } catch (error) {
      setPasses(false);
      if (error.response && error.response.status === 400) {
        showDefaultAlert('error', 'User does not exist', 'Please check the email and try again later.');
      } else {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
      }
      setLoading(false);
    }
  };

  const handleClaimsSearch = async () => {

    setLoading(true);
    setPasses(false);
    setListings(false);
    setLimitations(false);
    setManageUser(false);

    try {
      const response = await getClaims(searchTerm, token);
      setSearchResults(response);
      setLoading(false);
      setClaims(true);

    } catch (error) {
      setClaims(false);
      if (error.response && error.response.status === 400) {
        showDefaultAlert('error', 'User does not exist', 'Please check the email and try again later.');
      } else {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
      }
      setLoading(false);
    }
  };

  const handleListSearch = async () => {

    setLoading(true);
    setPasses(false);
    setClaims(false);
    setLimitations(false);
    setManageUser(false);

    try {
      const response = await getLists(searchTerm, token);
      setSearchResults(response);
      setListings(true);
      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 400) {
        setListings(false);
        showDefaultAlert('error', 'User does not exist', 'Please check the email and try again later.');
      } else {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
      }
      setLoading(false);
    }
  };

  const handleLimitationsSearch = async () => {

    setLoading(true);
    setPasses(false);
    setClaims(false);
    setListings(false);
    setManageUser(false);

    try {
      const response = await getUserDetails(searchTerm, token);
      setLimitations(true);
      setLoading(false);
      return response;

    } catch (error) {
      setLimitations(false);
      if (error.response && error.response.status === 400) {
        showDefaultAlert('error', 'User does not exist', 'Please check the email and try again later.');
      } else {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
      }
      setLoading(false);
    }
  };

  const handleUserSearch = async () => {

    setClaims(false);
    setListings(false);
    setLimitations(false);
    setPasses(false);
    setLoading(true);

    try {
      const response = await getUserDetails(searchTerm, token);
      setSearchResults(response);
      setManageUser(true);
      setLoading(false);

    } catch (error) {
      setManageUser(false);
      if (error.response && error.response.status === 400) {
        showDefaultAlert('error', 'User does not exist', 'Please check the email and try again later.');
      } else {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
      }
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="row">
      <div className="col-lg-10 col-md-9 col-sm-9" style={{ minHeight: '100%', position: 'relative' }}>
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col-2">
              <h6 style={{ padding: '12px', float: 'right' }}>User Email</h6>
            </div>
            <div className="col mb-2" style={{ marginTop: '5px', marginRight: '100px' }}>
              <input
                type="text"
                placeholder="Search from Email"
                className="form-control"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row text-center">
            {['Passes', 'Claims', 'Listings', 'API Limitations', 'User'].map((label, index) => (
              <div key={index} className="col-6 col-md-2 mb-3">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    const searchHandlers = [
                      handlePassesSearch,
                      handleClaimsSearch,
                      handleListSearch,
                      handleLimitationsSearch,
                      handleUserSearch
                    ];
                    searchHandlers[index]();
                  }}
                >
                  {label}
                </button>
              </div>
            ))}
          </div>
          <div>

          </div>
          {loading ? (<SpinnerA />) : (
            <div>
              {passes && <PassesTable searchResults={searchResults} searchTerm={searchTerm} />}
              {claims && <ClaimsTable searchResults={searchResults} />}
              {listings && <ListingTable searchResults={searchResults} />}
              {limitations && <LimitationsTable searchResults={searchResults} searchTerm={searchTerm} />}
              {manageUser && <ManageUser searchResults={searchResults} searchTerm={searchTerm} />}
            </div>
          )}
        </div >
      </div >
    </div>
  );
};

export default User;
