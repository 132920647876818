import React, { useContext, useEffect, useState } from 'react';

import { addDealerAdmin, getUsersByDealerId } from '../../services/DealerService';
import { UserContext } from '../../contexts/UserContext';
import { Button, Table } from 'react-bootstrap';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';
import AddAdminModel from './AddAdminModel';
import ReactPaginate from 'react-paginate';
import '../../App.css';
import FeaturedDealerModel from './FeaturedDealerModel';

const UserTable = ({ dealerId }) => {

  const { token } = useContext(UserContext);

  const [userData, setUserData] = useState([]);
  const [showAddAdminModel, setShowAddAdminModel] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [showFeaturedDealerModel, setShowFeaturedDealerModel] = useState(false);
  const [mode, setMode] = useState('');

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;
  const currentPageItems = userData?.slice(offset, offset + itemsPerPage);

  const handleAddAdminModel = () => {
    setShowAddAdminModel(true);
  };
  const handleFeaturedDealerModel = () => {
    setShowFeaturedDealerModel(true);
    setMode('add');
  };

  const handleSubmit = (email, dealerID) => {

    const confirmationMessage = 'Are you sure you want to add this admin?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await addDealerAdmin(email, dealerID, token);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', `Successfully Added the New Admin.`);
            setUserData((prevUserData) => {
              return prevUserData.map((user) => {
                if (user.Email === email) {
                  return { ...user, Roles: [...user.Roles, 'Admin'] };
                }
                return user;
              });
            });
          }
        } catch (error) {
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }
      }
    });
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await getUsersByDealerId(dealerId, token);
        if (response.status === 200) {
          setUserData(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data === '"No dealers registered for this company"') {
            setUserData([]);
          }
        } else {
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }

      }
    };
    getUsers();
  }, [dealerId, token]);


  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', margin: '10px' }}>
        <h5 className="text-muted mb-0">Add a New Admin</h5>
        <Button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={handleAddAdminModel}>Add Admin</Button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', margin: '10px' }}>
        <h5 className="text-muted mb-0">Make as Featured Dealer</h5>
        <Button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={() => handleFeaturedDealerModel()}>Featured Dealer</Button>
      </div>
      {userData?.length === 0 ? (
        <div>
          <h5>No Users Found</h5>
        </div>
      ) : (
        <div>
          <Table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Status</th>
                <th>Roles</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPageItems.map((user, index) => (
                <tr key={index}>
                  <td>{user.Email}</td>
                  <td>{user.Status}</td>
                  <td>{user.Roles?.join(', ')}</td>
                  <td>
                    <button
                      className='btn btn-danger'
                      style={{ width: 'auto', marginInline: '5px' }}
                      onClick={() => handleSubmit(user.Email, user.DealerID)}
                      disabled={user.Roles?.includes('Admin')}
                    >
                      Set Admin
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {userData && userData.length > itemsPerPage && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              pageCount={Math.ceil(userData.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => setCurrentPage(data.selected)}
              containerClassName={'pagination'}
              activeClassName={'active'}
              previousClassName={'pagination-previous'}
              nextClassName={'pagination-next'}
              pageClassName={'pagination-page'}
              breakClassName={'pagination-break'}
              previousLinkClassName={'pagination-previous-link'}
              nextLinkClassName={'pagination-next-link'}
              disabledClassName={'pagination-disabled'}
            />
          )}
        </div>
      )}
      <div>
        {showAddAdminModel && <AddAdminModel closeModel={setShowAddAdminModel} dealerID={dealerId} setUpdatedAdminData={setUserData} />}
      </div>
      <div>
        {showFeaturedDealerModel && <FeaturedDealerModel closeModel={setShowFeaturedDealerModel} dealerID={dealerId} mode={mode} />}
      </div>
    </div>

  );
};

export default UserTable;
