import Spinner from 'react-bootstrap/Spinner';

const SpinnerA = () => {
    return (
        <div className='spinner-container' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh'
        }}>
    <Spinner animation="border" role="status" >
        <span className="visually-hidden" >Loading...</span>
    </Spinner>
        </div >
    );
}

export default SpinnerA;
