import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { UserContext } from "../contexts/UserContext";
import { showConfirmationAlert, showDefaultAlert } from "../utils/alerts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  formatDateToCustomString,
  utcTimestampToNZTimezone,
} from "../utils/format";
import {
  addActiveFeaturedVehicle,
  updateActiveFeaturedVehicle,
} from "../services/VehicleService";
const regex = /^[a-zA-Z0-9]*$/; 

const FeaturedVehicleModel = ({
  closeModel,
  mode,
  selectedRow,
  setUpdatedData,
  setAddedData
}) => {
  const { token } = useContext(UserContext);
  const [show, setShow] = useState(true);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [invalidStartDate, setInvalidStartDate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState(0);
  const [position, setPosition] = useState("");
  const [VIN, setVIN] = useState("");
  const [VINError, setVINError] = useState(false);

  const validateForm = () => {
    if (start === null || end === null || position === "" || VIN === "" ) {
      setValidationError(true);
      return false;
    }
    if(VIN.length < 6) {
      setVINError(true);
      return false;
    }

    if (start > end) {
      setInvalidStartDate(true);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (mode === "update") {
      setStart(new Date(utcTimestampToNZTimezone(selectedRow?.StartTime)));
      setEnd(new Date(utcTimestampToNZTimezone(selectedRow?.EndTime)));
      setStartTime(utcTimestampToNZTimezone(selectedRow?.StartTime));
      setEndTime(utcTimestampToNZTimezone(selectedRow?.EndTime));
      setPosition(selectedRow?.Position);
      setStatus(selectedRow?.Status);
      setVIN(selectedRow?.VIN);
    }
  }, [selectedRow, mode]);

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  const handleVINChange = (event) => {
    const value = event.target.value;
    if (regex.test(value) || value) { 
      setVIN(value);
    }
    setValidationError(false);
    setInvalidStartDate(false);
    setVINError(false);
  };

  const handleStartDateChange = (date) => {
    setValidationError(false);
    setInvalidStartDate(false);
    setVINError(false);
    const formattedDate = formatDateToCustomString(date);
    setStart(date);
    setStartTime(formattedDate);
  };

  const handleEndDateChange = (date) => {
    setValidationError(false);
    setInvalidStartDate(false);
    setVINError(false);
    const formattedDate = formatDateToCustomString(date);
    setEnd(date);
    setEndTime(formattedDate);
  };

  const handlePositionDropDown = (eventKey) => {
    const positionValue = parseInt(eventKey);
    setPosition(positionValue);
    setValidationError(false);
    setInvalidStartDate(false);
    setVINError(false);
  };

  const handleStatusDropdown = (eventKey) => {
    setStatus(eventKey);
    setInvalidStartDate(false);
    setValidationError(false);
    setVINError(false);
  };

  const handleSubmit = async () => {
    if (!validateForm() || isSubmitting) {
      return;
    }
    const confirmationMessage =
      mode === "add"
        ? "Are you sure you want to add this featured vehicle?"
        : "Are you sure you want to update this featured vehicle?";

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        setIsSubmitting(true);
        try {
          if (mode === "add") {
            const response = await addActiveFeaturedVehicle(
              VIN,
              startTime,
              endTime,
              position,
              token
            );
            if (response.status === 200) {
              setAddedData(response.data);
              showDefaultAlert(
                "success",
                "Success",
                `Successfully Added the Featured Vehicle.`
              );
              handleClose();
            }
          } else if (mode === "update") {
            const response = await updateActiveFeaturedVehicle(
              VIN,
              startTime,
              endTime,
              position,
              status,
              token
            );
            if (response.status === 200) {
              setUpdatedData(response.data);
              showDefaultAlert(
                "success",
                "Success",
                `Successfully Updated the Featured Vehicle.`
              );
            }
          }
          handleClose();
        } catch (error) {
          setIsSubmitting(false);
          if (error.response && error.response.status === 400) {
            if (error.response.data === "VIN not exists.") {
              showDefaultAlert(
                "error",
                "VIN not exists.",
                "Please check the VIN number and try again."
              );
            } else if (error.response.data === "Vehicle is not claimed") {
              showDefaultAlert(
                "error",
                "Vehicle is not claimed",
                "Vehicle with given VIN is not claimed."
              );
            } else if (error.response.data === "Vehicle is not listed") {
              showDefaultAlert(
                "error",
                "Vehicle is not listed",
                "Vehicle with given VIN is not listed."
              );
            } else if (error.response.data === "Start time cannot be greater than or equal to end time") {
              showDefaultAlert(
                "error",
                "Invalid time range",
                "Start time cannot be greater than or equal to end time"
              );
            } else if (error.response.data === "A featured vehicle already exists with given time range and position") {
              showDefaultAlert(
                "error",
                "Featured vehicle already exists",
                "A featured vehicle already exists with given time range and position"
              );
            } else {
              showDefaultAlert(
                "error",
                "Error",
                "An error occurred. Please try again later."
              );
            }
          }
          else {
            showDefaultAlert(
              "error",
              "Error",
              "An error occurred. Please try again later."
            );
          }
          handleClose();
        }
      },
    });
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "add"
              ? "Make as Featured Vehicle"
              : "Update Featured Vehicle"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: "0 50px" }}>
          <Form>
            <Form.Group

              className="mb-3 d-flex align-items-center" controlId="exampleForm.ControlInputVIN"
            >
              <Form.Label style={{ color: "black", width:"100px"}}>VIN</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter VIN"
                value={VIN}
                disabled={mode === "add" ? false : true}
                style={{ marginLeft: "10px", width: "250px"}}
                onChange={handleVINChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 d-flex align-items-center" controlId="exampleForm.ControlInputStartTime"
            >
              <Form.Label style={{ color: "black", marginRight: "10px", width: "100px" }}>Start Time</Form.Label>
              <div style={{ color: "black" }}>
                <DatePicker
                  selected={start}
                  placeholderText={mode === "add" ? "Select Start Time" : start}
                  onChange={handleStartDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy HH:mm"
                  timeCaption="Time"
                  className="custom-datepicker-input"
                  minDate={new Date()}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3 d-flex align-items-center" controlId="exampleForm.ControlInputEndTime">
              <Form.Label style={{ color: "black", marginRight: "10px", width:"100px" }}>End Time</Form.Label>
              <div className="d-flex">
                <DatePicker
                  selected={end}
                  placeholderText={mode === "add" ? "Select End Time" : end}
                  onChange={handleEndDateChange}
                  className="custom-datepicker-input"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy HH:mm"
                  timeCaption="Time"
                  minDate={new Date()}
                />
              </div>
            </Form.Group>
            <Form.Group>
              <div className="mb-3 d-flex align-items-center" controlId="exampleForm.ControlInputPosition">
                <Form.Label style={{ color: "black", width: "100px" }}>Position</Form.Label>
                <Dropdown onSelect={handlePositionDropDown}>
                  <Dropdown.Toggle
                    style={{ marginLeft: "10px", color: "black", width: "150px" }}
                  >
                    {position === "" ? "Select Position" : position}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: '270px', overflow: 'auto' }}>
                    {[...Array(100)].map((_, index) => (
                      <Dropdown.Item eventKey={index + 1} key={index + 1}>
                        {index + 1}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Form.Group>
            {mode === "update" && (
              <Form.Group className="mb-3 d-flex align-items-center" controlId="exampleForm.ControlInputStatus">
                <Form.Label style={{ color: "black", width: "100px" }}>Status</Form.Label>
                <Dropdown onSelect={handleStatusDropdown}>
                  <Dropdown.Toggle
                    style={{ marginLeft: "10px", color: "black" }}
                  >
                    {status === "" ? "Select Status" : status}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="Active">
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Inactive">
                      Inactive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            )}
            {VINError && (
              <Form.Text style={{ color: "red", marginBottom: "10px" }}>
                Invalid VIN number.
              </Form.Text>
            )}
            {invalidStartDate && (
              <Form.Text style={{ color: "red", marginBottom: "10px" }}>
                Start Date cannot be after End Date.
              </Form.Text>
            )}
            {validationError && (
              <Form.Text style={{ color: "red", marginBottom: "10px" }}>
                Please fill all the fields.
              </Form.Text>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FeaturedVehicleModel;
