import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';
import { createUserPass } from '../../services/PassesService';
import { Passes } from '../../utils/data';

function AddPassModel({ closeModel, searchTerm, setPasses }) {

  const { token } = useContext(UserContext);

  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedPass, setSelectedPass] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if (selectedOption === "Single Pass") {
      setSelectedPass(Passes[0]);
    }
    else if (selectedOption === "Bundle Pass") {
      setSelectedPass(Passes[1]);
    }
    else {
      setSelectedPass(null);
    }
  }, [selectedOption]);


  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);

  };

  const handleAddPass = async () => {
    const confirmationMessage = 'Are you sure you want to add pass?';

    if (!selectedPass) {
      showDefaultAlert('error', 'Error', 'Please select a pass.');
      return;
    }
    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        setLoading(true);
        try {
          const response = await createUserPass(searchTerm, selectedPass.pricingName, selectedPass.numberOfPasses, selectedPass.claimValidity, selectedPass.passValidity, token);
          handleClose();
          if (response.status === 200) {
            setPasses(response.data);
            showDefaultAlert('success', 'Success', 'Successfully Added the passess.');
            setShow(false);
          }
        } catch (error) {
          setLoading(false);
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }
      },
      onCancel: () => {
        setLoading(false);
        handleClose();
      }

    });
  };

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title style={{ marginLeft: '170px' }}>Add Pass</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: 'auto' }}>
          <Form>
            <Form.Group className="mb-3 d-flex align-items-center" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black', width: '150px' }}>Select pass type  </Form.Label>
              <div className="col-6 mb-2" style={{ marginTop: '5px' }}>
                <select
                  className="form-control"
                  value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  <option value="" disabled>Select Pass</option>
                  <option value="Single Pass">Single Pass</option>
                  <option value="Bundle Pass">Bundle Pass</option>
                </select>
              </div>
            </Form.Group>
            {selectedPass && (
              <>
                <h3 className="claim-topic-color">Pass Details</h3>
                <table className="table vehicle-table">
                  <tbody>
                    <React.Fragment >
                      <tr className='vehi-detail-row'>
                        <th scope="row" className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>Pass Name</th>
                        <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{selectedPass.pricingName}</td>
                      </tr>
                      <tr className='vehi-detail-row'>
                        <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Number of Passes</th>
                        <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{selectedPass.numberOfPasses}</td>
                      </tr>
                      <tr className='vehi-detail-row'>
                        <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Pass Validity</th>
                        <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{selectedPass.passValidity} days</td>
                      </tr>
                      <tr className='vehi-detail-row'>
                        <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Claim Validity</th>
                        <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{selectedPass.claimValidity} days</td>
                      </tr>
                    </React.Fragment>
                  </tbody>
                </table>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" style={{ width: 'auto' }} onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button variant="primary" style={{ width: 'auto' }} onClick={handleAddPass} disabled={loading}>
            Add Pass
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddPassModel;