import React, { useContext, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { showDefaultAlert } from '../utils/alerts';
import { getalloutage } from '../services/APIService';
import { UserContext } from '../contexts/UserContext';
import APIOutageModel from '../components/APIOutageModel';
import { utcTimestampToNZTimezone } from '../utils/format';
import SpinnerA from "../components/Spinner";
import ReactPaginate from 'react-paginate';
import '../App.css';

const APIOutage = () => {

  const { token } = useContext(UserContext);
  const [outageData, setOutageData] = useState([]);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('');
  const [updatedOutage, setUpdatedOutage] = useState([]);
  const [addedOutage, setAddedOutage] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;
  const currentPageItems = outageData.slice(offset, offset + itemsPerPage);

  useEffect(() => {
    const setUpdateOutage = (updatedOutage) => {
      setOutageData((prevOutageData) => {
        return prevOutageData.map((outage) => {
          if (outage.CreatedAt === updatedOutage.CreatedAt) {
            return updatedOutage;
          }
          return outage;
        });
      });
    };
    setUpdateOutage(updatedOutage);
  }, [updatedOutage])

  useEffect(() => {
    const setAddedOutage = (addedOutage) => {
      setOutageData((prevOutageData) => {
        return [...prevOutageData, addedOutage];
      });
    };
    setAddedOutage(addedOutage);
  }, [addedOutage])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await getalloutage(token);
        setOutageData(response.data);

        setLoading(false);
      } catch (error) {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
      }
    };
    fetchData();
  }, [token]);

  const handleAddPassModel = () => {
    setShowUpdateModel(true);
    setMode('add');
  };

  const handleUpdatePassModel = (outage) => {
    setShowUpdateModel(true);
    setSelectedRow(outage);
    setMode('update');
  };

  return (
    <div style={{ width: "90%", margin: "auto", marginTop: "50px" }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
        <h5 className="text-muted mb-0">Add a new API Outage to the user</h5>
        <Button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={() => handleAddPassModel()}>Add API Outage</Button>
      </div>
      {loading ? (<SpinnerA />) : (<div>
        <Table responsive="sm" hover >
          <thead>
            <tr>
              <th>API</th>
              <th>Type</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {outageData.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <h5> No records found.</h5>
                </td>
              </tr>
            ) : (
              <React.Fragment>
                  {currentPageItems.map((outage,index) => (
                  <tr key={`${outage.CreatedAt}-${index}`}>
                    <td>{outage.API}</td>
                    <td>{outage.OutageType}</td>
                    <td>{utcTimestampToNZTimezone(outage.OutageStartDateTime)}</td>
                    <td>{utcTimestampToNZTimezone(outage.OutageEndDateTime)}</td>
                    <td>{outage.Status}</td>
                    <td>
                      <Button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={() => handleUpdatePassModel(outage)}>Update</Button>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={Math.ceil(outageData.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(data) => setCurrentPage(data.selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
          disabledClassName={'pagination-disabled'}
        />
      </div>)}
      {showUpdateModel && <APIOutageModel closeModel={setShowUpdateModel} selectedRow={selectedRow} mode={mode} setUpdateOutage={setUpdatedOutage} setAddedOutage={setAddedOutage} />}
    </div>
  );
};

export default APIOutage;