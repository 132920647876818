import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { getlistedVehicles, updateListRecord } from '../services/AprrovalService';
import VehicleModel from '../components/VehicleModel';
import { UserContext } from '../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../utils/alerts';
import { TimestampToDate, } from '../utils/format';
import SpinnerA from '../components/Spinner';
import ReactPaginate from 'react-paginate';
import '../App.css';

const Listings = () => {

  const [vehicleRecords, setVehicleRecords] = useState([]);
  const [showVehicleModel, setShowVehicleModel] = useState(false);
  const [vehiClaimID, setVehiClaimID] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { token } = useContext(UserContext);

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;
  const currentPageItems = vehicleRecords?.slice(offset, offset + itemsPerPage);

  const handleStatusUpdate = (claimID, status) => {

    const confirmationMessage = 'Are you sure you want to update the status?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await updateListRecord(claimID, status, token);
          const updatedRecords = vehicleRecords.filter(record => record.ClaimID !== response.data.ClaimID);
          setVehicleRecords(updatedRecords);
          showDefaultAlert('success', 'Success', 'Successfully updated the passess.');

          return response;
        } catch (error) {
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }
      }
    });
  };

  const handleVehicleModel = (claimID) => {
    setVehiClaimID(claimID);
    setShowVehicleModel(true);
  };

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getlistedVehicles(token);
        setVehicleRecords(response.data);
        setLoading(false);

      } catch (error) {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, [token]);


  return (
    <div style={{ width: "90%", margin: "auto", marginTop: "50px" }}>
      {loading ? (<SpinnerA />) : (<div>
        <Table responsive="sm" hover >
          <thead>
            <tr>
              <th>Listed Date</th>
              <th>CIN File</th>
              <th>Auto Trader</th>
              <th>Price</th>
              <th>Status</th>
              <th>Description</th>
              <th>Vehicle Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {vehicleRecords.length === 0 ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <h5> No records found.</h5>
                </td>
              </tr>
            ) : (
              <React.Fragment>
                {currentPageItems.sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
                  .map((vehicleRecord) => (
                    <tr key={vehicleRecord.ClaimID}>
                      <td>{TimestampToDate(vehicleRecord.CreatedAt)}</td>
                      <td>{vehicleRecord.cinFile === 'CIN not uploaded' ? <div>CIN not Uploaded</div> : <a href={vehicleRecord.SignedUrlCINFile} target="_blank" rel="noopener noreferrer">CIN file</a>}</td>
                      <td>{vehicleRecord.isAutoTrader === true ? <div>Yes</div> : <div>No</div>}</td>
                      <td>{vehicleRecord.Price === '' ? <div>Price not added</div> : <div>{vehicleRecord.Price}</div>}</td>
                      <td>{vehicleRecord.DescriptionStatus}</td>
                      <td>{vehicleRecord.Description}</td>
                      <td><Button className="btn btn-danger" style={{ width: 'auto', marginInline: '5px' }} onClick={() => handleVehicleModel(vehicleRecord.ClaimID)}>Details</Button></td>
                      <td>
                        <div>
                          {
                            <React.Fragment>
                              <Button className="btn btn-danger" style={{ width: 'auto', marginInline: '5px' }} onClick={() => handleStatusUpdate(vehicleRecord.ClaimID, 'Approved')}>Approve</Button>
                              <Button className="btn btn" style={{ width: 'auto', marginInline: '5px' }} onClick={() => handleStatusUpdate(vehicleRecord.ClaimID, 'Rejected')}>Reject</Button>
                            </React.Fragment>
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
                {showVehicleModel && <VehicleModel closeModel={setShowVehicleModel} vehiClaimID={vehiClaimID} />}
              </React.Fragment>
            )}
          </tbody>
        </Table>
        {vehicleRecords && vehicleRecords.length > itemsPerPage && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={Math.ceil(vehicleRecords.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(data) => setCurrentPage(data.selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousClassName={'pagination-previous'}
            nextClassName={'pagination-next'}
            pageClassName={'pagination-page'}
            breakClassName={'pagination-break'}
            previousLinkClassName={'pagination-previous-link'}
            nextLinkClassName={'pagination-next-link'}
            disabledClassName={'pagination-disabled'}
          />
        )}
      </div>)
      }
    </div>
  );
};

export default Listings;