import axios from "axios";

export const getActiveDealers = (token) => {
  return axios.post(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}getactivedealers`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const addDealerAdmin = (email, dealerID, token) => {
  return axios.post(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}addDealerAdmin`,
    { email, dealerID },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getUsersByDealerId = (dealerId, token) => {
  return axios.post(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}getAllDealers`,
    { dealerId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const addDealer = (companyName, traderNumber, token) => {
  return axios.post(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}add-dealer-company`,
    { companyName, traderNumber },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};

export const getAllFeaturedDealers = (token) => {
  return axios.get(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}featuredDealers`,
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
}

export const addFeaturedDealer = (dealerID, startTime, endTime, token) => {
  return axios.post(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}addFeaturedDealer`,
    { dealerID, startTime, endTime },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
}

export const updateFeaturedDealer = (dealerID, startTime, endTime, Status, token) => {
  return axios.post(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}updateFeaturedDealer`,
    { dealerID, startTime, endTime, Status },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
}

export const deleteFeaturedDealer = (dealerID, token) => {
  return axios.delete(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}featuredDealers/${dealerID}`,
    {
      headers:
        { Authorization: `Bearer ${token}` },
      data: { dealerID }
    }
  );
}


