import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { UserContext } from "../contexts/UserContext";
import { showDefaultAlert } from "../utils/alerts";
import { utcTimestampToNZTimezoneDateFormat } from "../utils/format";
import SpinnerA from "../components/Spinner";
import { getAllPromotions } from "../services/PromotionService";
import PromotionModel from "../components/PromotionModel";
import ReactPaginate from 'react-paginate';
import '../App.css';

const Promotion = () => {

	const [loading, setLoading] = useState(false);
	const [promotionRecords, setPromotionRecords] = useState([]);
	const { token } = useContext(UserContext);
	const [showPromotionModel, setShowPromotionModel] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [mode, setMode] = useState('');
	const [updatedPromotion, setUpdatedPromotion] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);

	const itemsPerPage = 10;
	const offset = currentPage * itemsPerPage;
	const currentPageItems = promotionRecords?.slice(offset, offset + itemsPerPage);

	useEffect(() => {
		const setUpdatePromotion = (updatedPromotion) => {
			setPromotionRecords((prevPromotionRecords) => {
				return prevPromotionRecords.map((promotion) => {
					if (promotion.PromotionID === updatedPromotion.PromotionID) {
						return updatedPromotion;
					}
					return promotion;
				});
			});
		};
		setUpdatePromotion(updatedPromotion);
	}, [updatedPromotion]);

	const handleAddPromotionModel = () => {
		setShowPromotionModel(true);
		setSelectedRow(null);
		setMode('add');
	};

	const handleUpdatePromotionModel = (promotion) => {
		setShowPromotionModel(true);
		setMode('update');
		setSelectedRow(promotion);
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await getAllPromotions(token);
				setPromotionRecords(response.data);
				setLoading(false);
			} catch (error) {
				showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
				setLoading(false);
			}
		};
		fetchData();
	}, [token]);

	return (
		<div style={{margin: "auto", marginTop: "50px" }}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
				<h5 className="text-muted mb-0">Add a new Promotion</h5>
				<Button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={handleAddPromotionModel}>Add Promotion</Button>
			</div>
			{loading ? (
				<SpinnerA />
			) : (
				<div>
					<Table responsive="sm" hover>
						<thead>
							<tr>
								<th>Promotion Name</th>
								<th>Start Time</th>
								<th>End Time</th>
								<th>Status</th>
								<th>Total Passes</th>
								<th>Available Passes</th>
								<th>Maximun Passes</th>
								<th>Claim Validity</th>
								<th>Pass validity</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{promotionRecords.length === 0 ? (
								<tr>
									<td colSpan="8" style={{ textAlign: "center" }}>
										<h5> No records found.</h5>
									</td>
								</tr>
							) : (
								<React.Fragment>
									{currentPageItems.map((promotionRecord) => (
										<tr key={promotionRecord.PromotionID}>
											<td>{promotionRecord.PromotionName}</td>
											<td>{utcTimestampToNZTimezoneDateFormat(promotionRecord.StartTime)}</td>
											<td>{utcTimestampToNZTimezoneDateFormat(promotionRecord.EndTime)}</td>
											<td>{promotionRecord.Status}</td>
											<td>{promotionRecord.TotalPasses}</td>
											<td>{promotionRecord.AvailablePasses}</td>
											<td>{promotionRecord.MaxPassesPerUser}</td>
											<td>{promotionRecord.ClaimValidity}</td>
											<td>{promotionRecord.PassValidity}</td>
											<td>
												<Button className="btn btn-danger" onClick={() => handleUpdatePromotionModel(promotionRecord)}>Update</Button>
											</td>
										</tr>
									))}
								</React.Fragment>
							)}
						</tbody>
					</Table>
						{promotionRecords.length > itemsPerPage && (
						<ReactPaginate
							previousLabel={'<'}
							nextLabel={'>'}
							breakLabel={'...'}
							pageCount={Math.ceil(promotionRecords.length / itemsPerPage)}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={(data) => setCurrentPage(data.selected)}
							containerClassName={'pagination'}
							activeClassName={'active'}
							previousClassName={'pagination-previous'}
							nextClassName={'pagination-next'}
							pageClassName={'pagination-page'}
							breakClassName={'pagination-break'}
							previousLinkClassName={'pagination-previous-link'}
							nextLinkClassName={'pagination-next-link'}
							disabledClassName={'pagination-disabled'}
						/>
					)}
				</div>
			)}
			{showPromotionModel && <PromotionModel closeModel={setShowPromotionModel} selectedRow={selectedRow} mode={mode} setAddedPromotion={setPromotionRecords} setUpdatedPromotion={setUpdatedPromotion} />}
		</div >
	);
};

export default Promotion;
