import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { updateUserStatus } from '../../services/AccountService';
import { UserContext } from '../../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';


const ManageUser = (props) => {

  const { token } = useContext(UserContext);

  const { searchResults } = props;
  const userID = searchResults.data.UserID;
  const [status, setstatus] = useState(searchResults.data.Status);

  const handleStatusChange = async (Status, statement) => {
    const confirmationMessage = `Are you sure you want to ${statement} the user account?`;

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await updateUserStatus(userID, Status, token);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', `The User successfully Updated.`);
            if (response.data === 'Account suspended.') {
              setstatus('Suspended');
            } else if (response.data === 'Account reinstigated.') {
              setstatus('Active');
            } else if (response.data === 'Account blocked.') {
              setstatus('Blocked');
            }
          }
          return response;

        } catch (error) {
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        }
      },
    });
  };

  useEffect(() => {
  }, [status]);

  return (
    <div>
      {status === 'Active' ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className="btn btn-danger"
            style={{ minWidth: '200px', width: 'fit-content', marginLeft: '20px' }}
            onClick={() => handleStatusChange('Suspended', 'Suspend')}
          >
            Suspend Account
          </Button>
        </div>
      ) : null}

      {status === 'Suspended' ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h4> User is currently Suspended </h4>
          <h6> Would you like to reinstate the account or block the account? </h6>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button
              className="btn btn-success"
              style={{ minWidth: '200px', width: 'fit-content', marginLeft: '20px' }}
              onClick={() => handleStatusChange('Active', 'Reinstigate')}
            >
              Reinstigate Account
            </Button>
            <Button
              className="btn btn-danger"
              style={{ minWidth: '200px', width: 'fit-content', marginLeft: '20px' }}
              onClick={() => handleStatusChange('Blocked', 'Block')}
            >
              Block Account
            </Button>
          </div>
        </div>
      ) : null}

      {status === 'Blocked' ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h6 style={{ display: 'flex', justifyContent: 'center' }}> User is currently Blocked </h6>
        </div>
      ) : null}
    </div>
  );
};

export default ManageUser;