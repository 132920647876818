import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Accordion } from 'react-bootstrap';
import { getActiveDealers } from '../services/DealerService';
import SpinnerA from '../components/Spinner';
import UserTable from '../components/dealer/UserTable';
import { showDefaultAlert } from '../utils/alerts';
import { Button } from 'react-bootstrap';
import DealerModel from '../components/dealer/DealerModel';
import ReactPaginate from 'react-paginate';
import '../App.css';

const Dealer = () => {

  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext);
  const [dealerData, setDealerData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;

  const offset = currentPage * itemsPerPage;
  const currentPageItems = dealerData?.slice(offset, offset + itemsPerPage);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {

    const getDealerData = async () => {
      setLoading(true);
      try {
        const response = await getActiveDealers(token);
        if (response.status === 200) {
          setDealerData(response.data);
        }
        setLoading(false);
      } catch (error) {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
        setLoading(false);
      }
    };
    getDealerData();
  }, [token]);

  const handleAddDealerModel = () => {
    setShowModel(true);
  };

  return (
    <div style={{ width: "90%", margin: "auto", marginTop: "50px" }}>
      <div>
        <h3 className="claim-topic-color">Dealers</h3>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
        <h5 className="text-muted mb-0">Add a new Dealer</h5>
        <Button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={() => handleAddDealerModel()}>Add Dealer</Button>
      </div>
      <div>
        {loading ? (<SpinnerA />) : (
          <div>
            <Accordion >
              {currentPageItems && currentPageItems.map((dealer, index) => (
                <Accordion.Item key={index} eventKey={index} onClick={() => handleAccordionClick(index)}>
                  <Accordion.Header>{dealer.CompanyName}</Accordion.Header>
                  <Accordion.Body>
                    {<UserTable dealerId={dealer.DealerID} />}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            {dealerData && dealerData.length > itemsPerPage && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              pageCount={Math.ceil(dealerData.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => setCurrentPage(data.selected)}
              containerClassName={'pagination'}
              activeClassName={'active'}
              previousClassName={'pagination-previous'}
              nextClassName={'pagination-next'}
              pageClassName={'pagination-page'}
              breakClassName={'pagination-break'}
              previousLinkClassName={'pagination-previous-link'}
              nextLinkClassName={'pagination-next-link'}
              disabledClassName={'pagination-disabled'}
            />
            )}
          </div>
        )}
        {showModel && <DealerModel closeModel={setShowModel} setDealerData={setDealerData} />}
      </div>
    </div>
  );
};

export default Dealer;