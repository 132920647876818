import React from 'react';
import SideBar from './SideBar';
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="row">
      <div className="col-lg-2 col-md-3 col-sm-3" >
        <SideBar />
      </div>
      <div className="col-lg-10 col-md-9 col-sm-9" >
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
