import { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Accordion, OverlayTrigger, Popover } from "react-bootstrap";
import CardBody from './vehicle/CardBody';
import ListGroup from 'react-bootstrap/ListGroup';
import { BsFillCheckCircleFill, BsFillXCircleFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import infoLogo from '../assets/img/about.svg';
import star_empty from '../assets/img/star-regular.svg';
import star_full from '../assets/img/star-solid.svg';
import star_half from '../assets/img/star-half-stroke-solid.svg';
import { getVehiDetailsForListRecord } from '../services/VehicleService';
import { TimestampToDate, UTCdateToFormattedDate } from '../utils/format';
import OdometerGraph from './vehicle/OdometerGraph';
import { UserContext } from '../contexts/UserContext';
import { showDefaultAlert } from '../utils/alerts';


function VehicleModel({ closeModel, vehiClaimID }) {

  const [show, setShow] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [ratingsData, setRatingsData] = useState(null);
  const [odometer, setOdometerReadings] = useState([]);
  const [chart, setChart] = useState([]);

  const { token } = useContext(UserContext);


  const replacerForUndefined = 'N/A';

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  const renderRatingStars = (finalStarRating, totalStarCount) => {
    const stars = [];
    for (let i = 0; i < totalStarCount; i++) {
      if (i < Math.floor(finalStarRating)) {
        stars.push(<img key={i} src={star_full} alt="Full Star" className="Rating_Star" />);
      } else if (i === Math.floor(finalStarRating) && finalStarRating % 1 !== 0) {
        stars.push(<img key={i} src={star_half} alt="Half Star" className="Rating_Star" />);
      } else {
        stars.push(<img key={i} src={star_empty} alt="Empty Star" className="Rating_Star" />);
      }
    }
    return (
      <div className="Rating" aria-label={`Rating of this item is ${finalStarRating} out of ${totalStarCount}`}>
        {stars}
      </div>
    );
  };

  const arrangeDetails = async (data) => {

    if (data && data.OdometerReadings && data.OdometerReadings.length > 0) {
      const latestOdometerReading = data.OdometerReadings?.reduce((latest, reading) => {
        const readingDate = reading[0].ReadingDate;
        return readingDate > latest.ReadingDate ? reading : latest;
      }, data.OdometerReadings[0][0]);
      setOdometerReadings(latestOdometerReading);
      setChart(data.OdometerReadings);
    }
    setVehicleData(data);

  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await getVehiDetailsForListRecord(vehiClaimID, token);
        setVehicleData(response.data);
        setRatingsData(response.data.ratings);
        arrangeDetails(response.data);
        setShow(true);
      } catch (error) {
        showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
      }
    };

    fetchData();

  }, [vehiClaimID, token]);

  const fuelEconomyPopOver = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p className='popover-text'>{ratingsData?.Ratings?.FuelEconomyDisclaimer}</p>
      </Popover.Body>
    </Popover>
  );

  const safetyRatingPopOver = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p className='popover-text'>The more stars a vehicle has, the safer it is for those in the vehicle, and those that may be struck by the vehicle in a crash.</p>
      </Popover.Body>
    </Popover>
  );

  const cleanCarPopOver = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p className='popover-text'>{ratingsData?.Ratings?.CleanVehicleDisclaimerLong}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-95w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Vehicle Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardBody>
            <br />
            {vehicleData && (
              <>
                <div >
                  <h5 className='details_fc' >Last updated date -
                    <span>{UTCdateToFormattedDate(vehicleData.UpdatedAt)}</span>
                  </h5>
                </div>
                <div >
                  <h4 className='details_fc' >Background Check</h4>
                  <hr />
                  <div className='row' >
                    {/* Money owed by vehicle check result */}
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 background-row">
                      <ListGroup horizontal>
                        <ListGroup.Item className="col-lg-8 col-md-8 col-sm-8 col-xs-8 detail-content"><h6 className='det_head'>Money Owing</h6>
                          <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }}>
                            Date -
                            {vehicleData?.MoneyOwingCheckDate ? UTCdateToFormattedDate(vehicleData.MoneyOwingCheckDate) : 'Unavailable'}
                          </p>
                          <p className="data_sou" style={{ fontSize: "12px", color: "gray" }}>Source - Personal Properties Securities Register</p>
                        </ListGroup.Item>
                        {vehicleData?.MoneyOwingStatus === 'false' ? (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 passed d-flex align-items-center justify-content-center" >
                            <BsFillCheckCircleFill /> &nbsp; Passed
                          </ListGroup.Item>
                        ) : vehicleData?.MoneyOwingStatus === 'true' ? (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 failed d-flex align-items-center justify-content-center">
                            <BsFillXCircleFill className='background-check-svg' /> &nbsp; Failed
                          </ListGroup.Item>
                        ) : (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4  unavailable d-flex align-items-center justify-content-center">
                            <BsFillQuestionCircleFill className='background-check-svg' /> &nbsp; Unavailable
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                    </div>
                    {/* stolen check result */}
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 background-row">
                      <ListGroup horizontal>

                        <ListGroup.Item className="col-lg-8 col-md-8 col-sm-8 detail-content">
                          <h6 className='det_head'>Reported Stolen</h6>
                          <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }} >
                            Date -
                            {vehicleData?.ReportedStolenCheckDate ? UTCdateToFormattedDate(vehicleData.ReportedStolenCheckDate) : 'Unavailable'}
                          </p>
                          <p className="data_sou" style={{ fontSize: "12px", color: "gray" }} >Source - Waka Kotahi</p>
                        </ListGroup.Item>
                        {vehicleData?.ReportedStolen === 'false' ? (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 passed d-flex align-items-center justify-content-center">
                            <BsFillCheckCircleFill /> &nbsp; Passed
                          </ListGroup.Item>
                        ) : vehicleData?.ReportedStolen === 'true' ? (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4  failed d-flex align-items-center justify-content-center">
                            <BsFillXCircleFill /> &nbsp; Failed
                          </ListGroup.Item>
                        ) : (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 unavailable d-flex align-items-center justify-content-center">
                            <BsFillQuestionCircleFill /> &nbsp; Unavailable
                          </ListGroup.Item>
                        )}

                      </ListGroup>
                    </div>
                  </div>
                </div>
                {/* Recall check result */}
                <div className="row mb-4">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 background-row">
                    <div>
                      <ListGroup horizontal>
                        <ListGroup.Item className="col-lg-8 col-md-8 col-sm-8 col-xs-8 detail-content"><h6 className='det_head' >Recall Status</h6>
                          <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }}>
                            Date -
                            {vehicleData?.RecalledStatusCheckDate ? UTCdateToFormattedDate(vehicleData.RecalledStatusCheckDate) : 'Unavailable'}
                          </p>
                          <p className="data_sou" style={{ fontSize: "12px", color: "gray" }}>Source - Waka Kotahi</p>
                        </ListGroup.Item>
                        {vehicleData?.RecalledStatus === 'Not known as recalled' ? (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 passed d-flex align-items-center justify-content-center">
                            <BsFillCheckCircleFill /> &nbsp; Passed
                          </ListGroup.Item>
                        ) : vehicleData?.RecalledStatus === 'Known recall with reference' ? (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 failed d-flex align-items-center justify-content-center">
                            <BsFillXCircleFill /> &nbsp; Failed
                          </ListGroup.Item>
                        ) : (
                          <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 unavailable d-flex align-items-center justify-content-center">
                            <BsFillQuestionCircleFill /> &nbsp; Unavailable
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                    </div>
                  </div>
                  {/* Imported Damage check result */}
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12  background-row">
                    <ListGroup horizontal>
                      <ListGroup.Item className="col-lg-8 col-md-8 col-sm-8 col-xs-8 detail-content"><h6 className='det_head' >Imported Damage</h6>
                        <p className="date" style={{ fontSize: "12px", color: "gray", marginBottom: "0.2px" }}>
                          Date -
                          {vehicleData?.UpdatedAt ? UTCdateToFormattedDate(vehicleData.UpdatedAt) : 'Unavailable'}
                        </p>
                        <p className="data_sou" style={{ fontSize: "12px", color: "gray" }}>Source - Waka Kotahi</p>
                      </ListGroup.Item>
                      {vehicleData?.IsImportedDamaged === 'false' ? (
                        <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 passed d-flex align-items-center justify-content-center">
                          <BsFillCheckCircleFill /> &nbsp; Passed
                        </ListGroup.Item>
                      ) : vehicleData?.IsImportedDamaged === 'true' ? (
                        <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 failed d-flex align-items-center justify-content-center">
                          <BsFillXCircleFill /> &nbsp; Failed
                        </ListGroup.Item>
                      ) : (
                        <ListGroup.Item className="col-lg-4 col-md-4 col-sm-4 col-xs-4 unavailable d-flex align-items-center justify-content-center">
                          <BsFillQuestionCircleFill /> &nbsp; Unavailable
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </div>
                </div>
              </>
            )}
            {/* ratings strats */}
            {ratingsData?.Ratings && (
              <>
                <>
                  <h4 className='details_fc my-4'>Safety and Environmental Ratings</h4>
                </>
                {ratingsData.Ratings.ErrorCode < 30 ? ( //check ratings error code 
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      {ratingsData.SafetyLabel && (
                        <>
                          <img className='mb-3' src={ratingsData.SafetyLabel} alt="S3 vfel" width={300} height={200} />
                          <br />
                        </>
                      )}
                      {ratingsData.VFELLabel && (
                        <>
                          <img className='mb-3' src={ratingsData.VFELLabel} alt="S3 safety" width={300} height={200} />
                          <br />
                        </>
                      )}
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                          {(ratingsData.Ratings.SafetyStarsText || ratingsData.Ratings.DriverSafetyStars || ratingsData.Ratings.DriverSafetyTest) && (
                            <div className='row-detail'>
                              <h6 className='details_fc'>
                                Safety Ratings
                              </h6>
                              <hr />
                              {ratingsData?.Ratings.SafetyStarsText && (
                                <>
                                  <span className='rating_det'> Safety Rating </span>
                                  <>
                                    <OverlayTrigger
                                      placement="bottom"
                                      trigger="click"
                                      overlay={safetyRatingPopOver}
                                      rootClose={true}
                                    >
                                      <img src={infoLogo} alt="info logo" className="info_logo" />
                                    </OverlayTrigger>
                                  </>
                                  {(() => {
                                    var ratingString = ratingsData.Ratings.SafetyStarsText;
                                    var ratingValues = ratingString.match(/\d+(\.\d+)?/g); // Extract numeric values from the string
                                    var finalStarRating = ratingValues[0]; // Assign the first number to finalStarRating
                                    var totalStarCount = ratingValues[ratingValues.length - 1]; // Assign the last number to totalStarCount
                                    return renderRatingStars(finalStarRating, totalStarCount); // Return the output from the renderRatingStars function
                                  })()}
                                  <br />
                                </>
                              )}

                              {ratingsData?.Ratings.DriverSafetyStars && (
                                <>
                                  <span className='rating_det'> Driver safety
                                  </span>
                                  {(() => {
                                    const finalStarRating = ratingsData.Ratings.DriverSafetyStars / 2;
                                    const totalStarCount = 5;  //out of 5
                                    return renderRatingStars(finalStarRating, totalStarCount);
                                  })()}
                                  <br />
                                </>
                              )}
                              {ratingsData?.Ratings.DriverSafetyTest && (
                                <>
                                  <span className='rating_det'> Safety rating system
                                  </span>
                                  <div className="">
                                    <span className='rating_h'>{ratingsData.Ratings.DriverSafetyTest}<b></b></span>
                                  </div>
                                  <br />
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div className='col-md-6 col-sm-12'>
                          {(ratingsData.Ratings.FuelStarsText || ratingsData.Ratings.CO2StarsText || ratingsData.Ratings.PollutantsStars) && (
                            <>
                              <h6 className='details_fc'>
                                Environmental ratings
                                <hr />
                              </h6>
                              {ratingsData.Ratings.FuelStarsText && (
                                <>
                                  <span className='rating_det me-1'> Fuel economy
                                  </span>

                                  {ratingsData.Ratings.FuelEconomyDisclaimer && (
                                    <>
                                      <OverlayTrigger
                                        placement="bottom"
                                        trigger="click"
                                        overlay={fuelEconomyPopOver}
                                        rootClose={true}
                                      >
                                        <img src={infoLogo} alt="info logo" className="info_logo" />
                                      </OverlayTrigger>
                                    </>
                                  )}
                                  {(() => {
                                    var ratingString = ratingsData.Ratings.FuelStarsText;
                                    var ratingValues = ratingString.match(/\d+(\.\d+)?/g); // Extract numeric values from the string
                                    var finalStarRating = ratingValues[0]; // Assign the first number to finalStarRating
                                    var totalStarCount = ratingValues[ratingValues.length - 1]; // Assign the last number to totalStarCount
                                    return renderRatingStars(finalStarRating, totalStarCount);
                                  })()}
                                  {ratingsData.Ratings.FuelEconomyText && (
                                    <>
                                      <span className='rating_det'>{ratingsData.Ratings.FuelEconomyText}</span>
                                    </>
                                  )}
                                  <br />
                                </>
                              )}
                              {ratingsData.Ratings.CO2StarsText && (
                                <>
                                  <span className='rating_det'> Carbon emissions
                                  </span>
                                  {(() => {
                                    var ratingString = ratingsData.Ratings.CO2StarsText;
                                    var ratingValues = ratingString.match(/\d+(\.\d+)?/g); // Extract numeric values from the string
                                    var finalStarRating = ratingValues[0]; // Assign the first number to finalStarRating
                                    var totalStarCount = ratingValues[ratingValues.length - 1]; // Assign the last number to totalStarCount
                                    return renderRatingStars(finalStarRating, totalStarCount);
                                  })()}
                                  {ratingsData.Ratings.CO2Text && (
                                    <>
                                      <span className='rating_det'>CO2Text
                                      </span>
                                    </>
                                  )}
                                  <br />
                                </>
                              )}
                              {ratingsData.Ratings.PollutantsStars && (
                                <>
                                  <span className='rating_det'> Air pollution
                                  </span>
                                  {(() => {
                                    const finalStarRating = ratingsData.Ratings.PollutantsStars / 2;
                                    const totalStarCount = 6;  //out of 12
                                    return renderRatingStars(finalStarRating, totalStarCount);
                                  })()}
                                  <br />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className='row'>
                        {(ratingsData.Ratings.CleanVehicleText || ratingsData.Ratings.CO2Disclaimer) && (
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <span className='rating_det me-1'> Clean Car rebate or fee
                            </span>
                            {ratingsData.Ratings.CleanVehicleDisclaimerLong && (
                              <>

                                <>
                                  <OverlayTrigger
                                    placement="bottom"
                                    trigger="click"
                                    overlay={cleanCarPopOver}
                                    rootClose={true}
                                  >
                                    <img src={infoLogo} alt="info logo" className="info_logo" />
                                  </OverlayTrigger>
                                </>

                                {/* <img src={infoLogo} alt="info logo" className="info_logo" onClick={cleanCarInfoClick} /> */}
                                {/* <Popover
                                      id={idCleanCarPop}
                                      open={openCleanCarPop}
                                      style={{
                                        width: '1000px',
                                      }}
                                      anchorEl={cleanCarPop}
                                      onClose={cleanCarInfoClose}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                    >
                                      <p className='popover-text'>{ratingsData.Ratings.CleanVehicleDisclaimerLong}</p>
                                    </Popover> */}
                              </>
                            )}
                            <div className='rating_clean_car'>
                              <div className='py-2 px-2'>
                                {ratingsData.Ratings.CleanVehicleText && (
                                  <>
                                    <span><b>{ratingsData.Ratings.CleanVehicleText}</b></span><br />
                                  </>
                                )}
                                {ratingsData.Ratings.CO2Disclaimer && (
                                  <>
                                    <span className='rating_det'> {ratingsData.Ratings.CO2Disclaimer}</span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='row'>
                    <div className='col-md-6 col-lg-6 col-sm-6 col-xs-6 row-detail'>
                      <h6 className='details_fc'>
                        <a href={'https://rightcar.govt.nz/'} target='_blank' rel='noopener noreferrer'>Please click and find Ratings</a>
                      </h6>
                    </div>
                  </div>
                )}
                <div>
                  <hr />
                </div>
              </>
            )}
            {/* Listing details */}
            {vehicleData && vehicleData.ClaimStatus && (
              <>
                <br />
                <h4 className='details_fc'>Listing Details</h4>
                <br />
                <Accordion>
                  <Accordion.Item >
                    <Accordion.Header>Listing</Accordion.Header>
                    <Accordion.Body>
                      <table className="table vehicle-table">
                        <tbody>
                          {vehicleData.ClaimRecord && (
                            <tr className='vehi-detail-row'>
                              <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Vehicle Location</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6"> location</td>
                            </tr>
                          )}
                          <tr className='vehi-detail-row'>
                            <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Listed Status</th>
                            <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">listed</td>
                          </tr>
                          {vehicleData?.ListStatus && vehicleData.ListRecord?.Price &&
                            <tr className='vehi-detail-row'>
                              <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Vehicle Price</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">${vehicleData.ListRecord.Price}</td>
                            </tr>
                          }
                          {vehicleData.ListStatus && vehicleData.ListRecord?.cinFile !== "CIN not uploaded" &&
                            <tr className='vehi-detail-row'>
                              <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Consumer Information Notice</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6"><a href={vehicleData.ListRecord.cinFile} target="_blank" rel="noopener noreferrer">CIN File</a></td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )}
            {vehicleData && vehicleData.ClaimStatus && (
              <>
                <br />
                <h4 className='details_fc'>Listing Details</h4>
                <br />
                <Accordion>
                  <Accordion.Item >
                    <Accordion.Header>Listing</Accordion.Header>
                    <Accordion.Body>
                      <table className="table vehicle-table">
                        <tbody>
                          {vehicleData.ClaimRecord && (
                            <tr className='vehi-detail-row'>
                              <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Vehicle Location</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6"></td>
                            </tr>
                          )}
                          <tr className='vehi-detail-row'>
                            <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Listed Status</th>
                            <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">listed</td>
                          </tr>
                          {vehicleData?.ListStatus && vehicleData.ListRecord?.Price &&
                            <tr className='vehi-detail-row'>
                              <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Vehicle Price</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">${vehicleData.ListRecord.Price}</td>
                            </tr>
                          }
                          {vehicleData.ListStatus && vehicleData.ListRecord?.cinFile !== "CIN not uploaded" &&
                            <tr className='vehi-detail-row'>
                              <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Consumer Information Notice</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6"><a href={vehicleData.ListRecord.cinFile} target="_blank" rel="noopener noreferrer">CIN File</a></td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )}
            {vehicleData && (
              <>
                <br />
                <h4 className='details_fc'>Vehicle Details</h4>
                <br />
                <>
                  <Accordion >
                    <Accordion.Item >
                      <Accordion.Header>Vehicle</Accordion.Header>
                      <Accordion.Body>
                        <table className="table vehicle-table">
                          <tbody>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>Year Of Manufacture</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.YearOfManufacture}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6" >Make</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Make || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Model</th>
                              <td className=" col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Model || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">VIN</th>
                              <td className=" col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.VIN || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Chassis Number</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.ChassisNumber || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Body Style</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.BodyStyle || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Vehicle Type</th>
                              <td className=" col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.VehicleType || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Body Basic Colour</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.BasicColour || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Assembly Type</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6" >{vehicleData.AssemblyType || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Number Of Seats</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.NumberOfSeats || replacerForUndefined}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <br />
                  <Accordion>
                    <Accordion.Item>
                      <Accordion.Header>Engine</Accordion.Header>
                      <Accordion.Body>
                        <table className="table vehicle-table">
                          <tbody>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Engine Number</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.EngineNumber || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Fuel Type</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.FuelType || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">CCRating</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.CCRating || replacerForUndefined}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <br />
                  <h4 className='details_fc'>Registration and License Details</h4>
                  <br />
                  <Accordion>
                    <Accordion.Item>
                      <Accordion.Header>Registration</Accordion.Header>
                      <Accordion.Body>
                        <table className="table vehicle-table">
                          <tbody>
                            <tr className='vehi-detail-row '>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Vehicle Registration Status</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6"> {vehicleData.VehicleRegistrationStatus || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Date Of First NZ Registration</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{UTCdateToFormattedDate(vehicleData.DateOfFirstNZRegistration) || replacerForUndefined} </td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Registration Plate Number</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.PlateNumber || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Plate Status</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Status || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Plate Type</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.PlateType || replacerForUndefined} </td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Plate Effective Date</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{UTCdateToFormattedDate(vehicleData.EffectiveDate) || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Previous Country Of Registration</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6"> {vehicleData.PreviousCountryOfRegistration || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Is Imported Damaged</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.IsImportedDamaged === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Is Registered Overseas</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.IsRegisteredOverseas === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Cause Of Latest Registration</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.CauseOfLatestRegistration || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Country Of Origin</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.CountryOfOrigin || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Date Of Last Registration</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{UTCdateToFormattedDate(vehicleData.DateOfLastRegistration) || replacerForUndefined}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
                <br />
                {vehicleData.Registrations && (
                  <Accordion>
                    <Accordion.Item>
                      <Accordion.Header>Vehicle License</Accordion.Header>
                      <Accordion.Body>
                        <table class="table vehicle-table">
                          <tbody>
                            <tr>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Continuous License:</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Registrations?.IsContinous === "false" ? "No" : "Yes"}</td>
                            </tr>
                            <tr>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">License Number:</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6" >{vehicleData.Registrations?.Number || replacerForUndefined}</td>
                            </tr>
                            <tr>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">License Issue Date:</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6" >{vehicleData.Registrations?.IssueDateTime ? UTCdateToFormattedDate(vehicleData.Registrations.IssueDateTime) : replacerForUndefined}</td>
                            </tr>
                            <tr>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Expiry DateTime:</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Registrations?.ExpiryDateTime ? UTCdateToFormattedDate(vehicleData.Registrations.ExpiryDateTime) : replacerForUndefined}</td>
                            </tr>
                            <tr>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">License Type:</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6" >{vehicleData.Registrations?.LicenseType || replacerForUndefined}</td>
                            </tr>
                            <tr className='vehi-detail-row'>
                              <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Vehicle Usage</th>
                              <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.VehicleUsageCode || replacerForUndefined}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
                {vehicleData.Inspections && (
                  <>
                    <br />
                    <h4 className='details_fc'>Inspection Details</h4>
                    <br />
                    <Accordion>
                      <Accordion.Item>
                        <Accordion.Header>Warrant of Fitness (WOF) / Certificate of Fitness (COF)</Accordion.Header>
                        <Accordion.Body>
                          <table class="table vehicle-table">
                            <tbody>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Subject To COF</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.SubjectToCOF === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Subject To RUC</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.SubjectToRUC === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Subject To WOF</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.SubjectToWOF === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Vehicle System Id</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.VehicleSystemId || replacerForUndefined}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Date</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Inspections?.Date ? UTCdateToFormattedDate(vehicleData.Inspections.Date) : replacerForUndefined}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Expiry Date</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Inspections?.ExpiryDate ? UTCdateToFormattedDate(vehicleData.Inspections.ExpiryDate) : replacerForUndefined}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">BTN</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Inspections?.BTN || replacerForUndefined}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Description</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Inspections?.Description || replacerForUndefined}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">IsLatest</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Inspections?.IsLatest === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Type</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData.Inspections?.Type || replacerForUndefined}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                )}
                {odometer && (
                  <>
                    <br />
                    <h4 className='details_fc'>Vehicle History</h4>
                    <br />
                    <Accordion>
                      <Accordion.Item>
                        <Accordion.Header>Odometer Reading</Accordion.Header>
                        <Accordion.Body>
                          <table class="table vehicle-table">
                            <tbody>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Reliable Odometer</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.ReliableOdometer === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Reading</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{odometer?.Reading || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Reading Date</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{UTCdateToFormattedDate(odometer?.ReadingDate) || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Unit</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{odometer?.Unit || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Source</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{odometer?.Source || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Plate Effective Date</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.EffectiveDate ? UTCdateToFormattedDate(vehicleData.EffectiveDate) : replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Previous Country Of Registration</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6"> {vehicleData?.PreviousCountryOfRegistration || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Is Imported Damaged</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.IsImportedDamaged === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Is Registered Overseas</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.IsRegisteredOverseas === "false" ? "No" : "Yes" || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Cause Of Latest Registration</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.CauseOfLatestRegistration || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Country Of Origin</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.CountryOfOrigin || replacerForUndefined}</td>
                              </tr>
                              <tr className='vehi-detail-row'>
                                <th scope="row" className="col-lg-6 col-md-6 col-sm-6 col-xs-6">Date Of Last Registration</th>
                                <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.DateOfLastRegistration ? UTCdateToFormattedDate(vehicleData.DateOfLastRegistration) : replacerForUndefined}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div>
                      <br />
                      &nbsp;
                      <h4 className='details_fc'>Odometer Readings with Years</h4>
                      <br />
                    </div>
                    <div>
                      {/* display timeline grapgh  */}
                      <OdometerGraph chart={chart} />
                    </div>
                  </>
                )}
              </>
            )}
          </CardBody>
          {vehicleData && (
            <CardBody>
              <br />
              <h4 className='details_fc'>Vehicle Summary</h4>
              <br />
              <Accordion>
                <Accordion.Item >
                  <Accordion.Header>Vehicle Usage</Accordion.Header>
                  <Accordion.Body>
                    <table class="table vehicle-table">
                      <tbody>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Plate Number</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.PlateNumber || replacerForUndefined}</td>
                        </tr>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Plate Status</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.Status || replacerForUndefined}</td>
                        </tr>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Plate Type</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.PlateType || replacerForUndefined}</td>
                        </tr>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Effective Date</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.EffectiveDate ? UTCdateToFormattedDate(vehicleData.EffectiveDate) : replacerForUndefined}</td>
                        </tr>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Model</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.Model || replacerForUndefined}</td>
                        </tr>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Make</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.Make || replacerForUndefined}</td>
                        </tr>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Body Style</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.BodyStyle || replacerForUndefined}</td>
                        </tr>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>VIN</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.VIN || replacerForUndefined}</td>
                        </tr>
                        <tr className='vehi-detail-row'>
                          <th scope="row" className=' col-lg-6 col-md-6 col-sm-6 col-xs-6'>Vehicle Usage Code</th>
                          <td className="col-lg-6 col-md-6 col-sm-6 col-xs-6">{vehicleData?.VehicleUsageCode || replacerForUndefined}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </CardBody>
          )}
        </Modal.Body>
      </Modal>
    </>
  );

}

export default VehicleModel;