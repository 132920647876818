import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { TimestampToDate } from '../../utils/format';
import VehicleModel from '../VehicleModel';

export const ListingTable = (props) => {

  const { searchResults } = props;
  const { IndividualClaimRecords, DealerListRecords } = searchResults.data;

  const [showVehicleModel, setShowVehicleModel] = useState(false);
  const [vehiClaimID, setVehiClaimID] = useState();

  const handleVehicleModel = (claimID) => {
    setVehiClaimID(claimID);
    setShowVehicleModel(true);
  };
  return (
    <div>
      <div>
        <h3 className="claim-topic-color">Listings!</h3>
        <Accordion defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Personal Listings</Accordion.Header>
            <Accordion.Body>
              {IndividualClaimRecords.length === 0 ? (
                <div>
                  <h6 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Listed Vehicles Found</h6>
                </div>
              ) : (
                <table className="table align-middle mb-0 mt-2 bg-white">
                  <thead className="bg-light">
                    <tr>
                      <th>Plate Number/VIN</th>
                      <th>Listed Date</th>
                      <th>CIN File</th>
                      <th>Auto Trader</th>
                      <th>Price</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {IndividualClaimRecords.map((listedVehicle) => (
                      <tr key={listedVehicle.ClaimID}>
                        <td style={{ width: '20%' }}>
                          <p className="text-muted mb-0">{listedVehicle.PlateNumber || listedVehicle.VIN}</p>
                        </td>
                        <td style={{ width: '20%' }}>
                          <p className="text-muted mb-0">{TimestampToDate(listedVehicle.CreatedAt)}</p>
                        </td>
                        <td style={{ width: '20%' }}>
                          {listedVehicle.cinFile === 'CIN not uploaded' ? <div>CIN not Uploaded</div> : <a href={listedVehicle.SignedUrlCINFile} target="_blank" rel="noopener noreferrer">CIN file</a>}
                        </td>
                        <td style={{ width: '20%' }}>
                          {listedVehicle.isAutoTrader === true ? <div>Yes</div> : <div>No</div>}
                        </td>
                        <td style={{ width: '20%' }}>
                          {listedVehicle.Price === '' ? <div>Price not added</div> : <>{listedVehicle.Price}</>}
                        </td>
                        <td>
                          <Button className="btn btn-danger" onClick={() => handleVehicleModel(listedVehicle.ClaimID)}>Details</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br />
        <Accordion defaultActiveKey={['0']} >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Dealer Listings</Accordion.Header>
            <Accordion.Body>
              {DealerListRecords.length === 0 ? (
                <div>
                  <h6 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Listed Vehicles Found</h6>
                </div>
              ) : (
                <table className="table align-middle mb-0 mt-2 bg-white">
                  <thead className="bg-light">
                    <tr>
                      <th>Plate Number/VIN</th>
                      <th>Listed Date</th>
                      <th>CIN File</th>
                      <th>Auto Trader</th>
                      <th>Price</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DealerListRecords.map((listedVehicle) => (
                      <tr key={listedVehicle.ClaimID}>
                        <td style={{ width: '20%' }}>
                          <p className="text-muted mb-0">{listedVehicle.PlateNumber || listedVehicle.VIN}</p>
                        </td>
                        <td style={{ width: '20%' }}>
                          <p className="text-muted mb-0">{TimestampToDate(listedVehicle.CreatedAt)}</p>
                        </td>
                        <td style={{ width: '20%' }}>
                          {listedVehicle.cinFile === 'CIN not uploaded' ? <div>CIN not Uploaded</div> : <a href={listedVehicle.SignedUrlCINFile} target="_blank" rel="noopener noreferrer">CIN file</a>}
                        </td>
                        <td style={{ width: '20%' }}>
                          {listedVehicle.isAutoTrader === true ? <div>Yes</div> : <div>No</div>}
                        </td>
                        <td style={{ width: '20%' }}>
                          {listedVehicle.Price === '' ? <div>Price not added</div> : <>{listedVehicle.Price}</>}
                        </td>
                        <td>
                          <Button className="btn btn-danger" onClick={() => handleVehicleModel(listedVehicle.ClaimID)}>Details</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {showVehicleModel && <VehicleModel closeModel={setShowVehicleModel} vehiClaimID={vehiClaimID} />}
      </div>
    </div >
  );
};
