import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { UTCdateToFormattedDate } from '../../utils/format';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);

export default function OdometerGraph({ chart }) {

  //create timeline grapgh
  if (!chart) {
    return null; // or some other appropriate value
  }
  var data = {
    labels: chart.slice(0)
      .reverse()
      .map((x) => UTCdateToFormattedDate(x[0].ReadingDate)),
    datasets: [
      {
        label: 'Km value',
        fill: true,
        data: chart.slice(0)
          .reverse()
          .map((x) => x[0].Reading),
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)'],
        pointBackgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 3,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <>
      <Line data={data} height={500} options={options} />
    </>
  );
}
