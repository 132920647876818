import axios from "axios";

const getSignedUrl = async (imageID, file, action, token) => {
  return await axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}car-signed-url`, { imageID, fileType: file.type, action },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    });
};

const addVehicleDefinition = (make, model, fuelType, year, images, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}car-definitions`, { make, model, fuelType, year, images },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    });
};

const getAllVehicleDefinitions = (token) => {
  return axios.get(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}car-definitions`,
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};

const updateVehicleDefinition = (vehicleID, make, model, fuelType, year, images, token) => {
  return axios.patch(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}car-definitions`, { vehicleID, make, model, fuelType, year, images },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    });
};

const deleteVehicleDefinition = (vehicleID, token) => {
  return axios.delete(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}car-definitions/${vehicleID}`,
    {
      headers:
        { Authorization: `Bearer ${token}` },
    });
};

export {
  getSignedUrl,
  getAllVehicleDefinitions,
  updateVehicleDefinition,
  deleteVehicleDefinition,
  addVehicleDefinition,
};
