import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../utils/alerts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/css/APIOutageModel.css';
import { createOutage, updateOutage } from '../services/APIService';
import { formatDateToCustomString, utcTimestampToNZTimezone } from '../utils/format';

const APIOutageModel = ({ closeModel, selectedRow, mode, setUpdateOutage, setAddedOutage }) => {

  

  const { token } = useContext(UserContext);
  const outageData = selectedRow;

  const [show, setShow] = useState(false);
  const [api, setApi] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [newStart, setNewStart] = useState("");
  const [newEnd, setNewEnd] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [invalidStartDate, setInvalidStartDate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);



  const validateForm = () => {
    if (api === "" ||
       type === "" || 
       start === null || 
       end === null || 
       status === "") {
      setValidationError(true);
      return false;
    }
    if (start > end) {
      setInvalidStartDate(true);
      return false;
    }
    return true;
  };

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  useEffect(() => {

    setShow(true);
    if (mode === 'update') {
      setApi(outageData?.API);
      setType(outageData?.OutageType);
      setStatus(outageData?.Status);
      setStart(new Date(utcTimestampToNZTimezone(selectedRow?.OutageStartDateTime)));
      setEnd(new Date(utcTimestampToNZTimezone(selectedRow?.OutageEndDateTime)));
      setNewStart(utcTimestampToNZTimezone(selectedRow?.OutageStartDateTime))
      setNewEnd(utcTimestampToNZTimezone(selectedRow?.OutageEndDateTime))
    }
  }, [mode, outageData, selectedRow]);

  const handleAPIDropDown = (eventKey) => {
    setApi(eventKey);
    setValidationError(false);
    setInvalidStartDate(false);
  };

  const handleTypeDropDown = (eventKey) => {
    setType(eventKey);
    setValidationError(false);
    setInvalidStartDate(false);
  };

  const handleTimeZoneDropdown = (eventKey) => {
    setStatus(eventKey);
    setInvalidStartDate(false);
    setValidationError(false);
  };

  const handleStartDateChange = (date) => {
    const formattedDate = formatDateToCustomString(date);
    setStart(date)
    setNewStart(formattedDate);
    setInvalidStartDate(false);
    setValidationError(false);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = formatDateToCustomString(date);
    setEnd(date);
    setNewEnd(formattedDate);
    setInvalidStartDate(false);
    setValidationError(false);
  };

  const handleSubmit = async () => {
    if (!validateForm() || isSubmitting) {
      return;
    }
    const confirmationMessage = 'Are you sure you want to update the selected APIs?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        setIsSubmitting(true);
        try {
          if (mode === 'add') {
            const response = await createOutage(api, type, newStart, newEnd, status, token);
            if (response.status === 200) {
              showDefaultAlert('success', 'Success', `Successfully Added the API Outage.`);
              setAddedOutage(response.data);
            }
          } else if (mode === 'update') {
            const response = await updateOutage(api, type, newStart, newEnd, status, token);
            if (response.status === 200) {
              setUpdateOutage(response.data);
              showDefaultAlert('success', 'Success', `Successfully Updated the API Outage.`);
            }
          }
          handleClose();
        } catch (error) {
          setIsSubmitting(false);
          showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          handleClose();
        }
      },
    });

  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>{mode === 'add' ? 'Add API Outage' : 'Update API Outage'}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: 'auto' }}>
          <Form>
            <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black', width: '150px' }} >Select API</Form.Label>
              {mode === 'add' ? (
                <Dropdown data-bs-theme="light" onSelect={(handleAPIDropDown)} >
                  <Dropdown.Toggle style={{ color: 'black' }}>
                    {api === '' ? 'Select API' : api}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="CLAIM">Claim</Dropdown.Item>
                    <Dropdown.Item eventKey="LIST">List</Dropdown.Item>
                    <Dropdown.Item eventKey="STOLEN">Stolen</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Form.Control type="text" placeholder={api} disabled />
              )}
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput2">
              <Form.Label style={{ color: 'black', width: '150px' }}>Type</Form.Label>
              {mode === 'add' ? (
                <Dropdown data-bs-theme="light" onSelect={(handleTypeDropDown)} >
                  <Dropdown.Toggle style={{ color: 'black' }}>
                    {type === '' ? 'Select Type' : type}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="NZTA">NZTA</Dropdown.Item>
                    <Dropdown.Item eventKey="PPSR">PPSR</Dropdown.Item>
                    <Dropdown.Item eventKey="Personal">Personal</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Form.Control type="text" placeholder={type} disabled />
              )}
            </Form.Group>

            <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black', width: '150px' }}>Start Time</Form.Label>
              <div className="d-flex">
                <DatePicker
                  selected={start}
                  placeholderText={mode === 'add' ? 'Select Start Time' : start}
                  onChange={handleStartDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy HH:mm"
                  timeCaption="Time"
                  className="custom-datepicker-input"
                  minDate={new Date()}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput2">
              <Form.Label style={{ color: 'black', width: '150px' }}>End Time</Form.Label>
              <div className="d-flex">
                <DatePicker
                  selected={end}
                  placeholderText={mode === 'add' ? 'Select End Time' : end}
                  onChange={handleEndDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy HH:mm"
                  timeCaption="Time"
                  minDate={new Date()}
                  className="custom-datepicker-input"
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput3">
              <Form.Label style={{ color: 'black', width: '150px' }}>Status</Form.Label>
              <Dropdown data-bs-theme="light" onSelect={(handleTimeZoneDropdown)}>
                <Dropdown.Toggle style={{ color: 'black' }} >
                  {status === '' ? 'Select Status' : status}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                  <Dropdown.Item eventKey="Inactive">Inactive</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Form>
          {invalidStartDate && (
            <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              Start Date cannot be after End Date.
            </Form.Text>
          )}
          {validationError && (
            <Form.Text style={{ color: "red", marginBottom: "10px" }}>
              Please fill all the fields.
            </Form.Text>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
            {mode === 'add' ? 'Add Outage' : 'Update Outage'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default APIOutageModel;