import React, { useEffect } from 'react';
import '../assets/css/SingleSignOn.css';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export default function SingleSignOn() {
  const navigate = useNavigate();

  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        navigate('/');
      } catch (error) {
        console.error('Error fetching authendicated user', error);
      }
    };
    getUserAttributes();
  }, []);

  const handleSignIn = async (provider) => {
    try {
      await Auth.federatedSignIn({ provider });
    } catch (error) {
      console.error('Error signing in with federated provider:', error);
    }
  };

  return (
    <div>
      <div className="inner">
        <div className="">
          <label className="custom-control-label">Sign in to Waka Online Operator App</label>
        </div>
        <div className="social-buttons mt-2">
          <button
            onClick={() => { handleSignIn('Microsoft'); }}
            className="SSO-Google btn-lg btn-block mt-3"
          >
            Microsoft
          </button>
        </div>
      </div>
    </div>
  );
}