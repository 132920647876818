import axios from "axios";

export const getVehiDetailsForListRecord = (vehiClaimID, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}getVehiDetailsForListRecord`,
    { claimID: vehiClaimID },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

  );
};

export const getVehicleDetails = (plateNumberOrVIN, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}getVehicleDetails`,
    { plateNumberOrVIN: plateNumberOrVIN },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
};

export const getVehicleRatings = (VIN) => {
  return axios.post(`${process.env.REACT_APP_API_COMMON}ratings`,
    { VIN: VIN }
    ,
);
};

export const getAllFeaturedVehicles = (token) => {
  return axios.get(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}featuredVehicles`,
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
}

export const addActiveFeaturedVehicle = (VIN, startTime, endTime, position, token) => {
  return axios.post(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}addActiveFeaturedVehicle`,
    { VIN, startTime, endTime, position },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
}

export const updateActiveFeaturedVehicle = (VIN, startTime, endTime, position, status, token) => {
  return axios.post(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}updateActiveFeaturedVehicle`,
    { VIN, startTime, endTime, position, status },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
}

export const deleteFeaturedVehicle = (VIN, token) => {
  return axios.delete(
    `${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}featuredVehicles/${VIN}`,
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
}