
//create function to convert UTC date to normal formatted date
//import relevent packages

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en';

// Extend dayjs with the UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//UTC date to normal formatted date
const TimestampToDate = (timestamp) => {
	const formattedDate = dayjs(timestamp * 1000).tz(browserTimezone).format('MMM DD, YYYY');
	return formattedDate;
};

//timestamp to normal yyyy-mm-dd hh:mm:ss format
const TimestampToFormattedDate = (timestamp) => {
	const formattedDate = dayjs(timestamp * 1000).tz(browserTimezone).format('YYYY-MM-DD HH:mm:ss');
	return formattedDate;
};

//UTC date with millisecond to normal formatted date
const UTCdateWithMillisecondToDate = (utcDate) => {
	const formattedDate = dayjs(utcDate).tz(browserTimezone).format('MMM DD, YYYY');
	return formattedDate;
};

//UTC date to normal formatted date
const UTCdateToFormattedDate = (utcDate) => {
	const formattedDate = dayjs(utcDate).tz(browserTimezone).format('MMM DD, YYYY');
	return formattedDate;
};


const formatDateToCustomString = (date) => {
	if (date instanceof Date && !isNaN(date)) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		return formattedDate;
	} else {
		return "Invalid Date";
	}
};

function utcTimestampToNZTimezone(utcTimestamp) {
	const nztimezone = 'Pacific/Auckland';
	const utcDatetime = new Date(utcTimestamp * 1000);
	const formattedDate = dayjs(utcDatetime).tz(nztimezone).format('MMM DD, YYYY HH: mm: ss');
	return formattedDate;
}

function utcTimestampToNZTimezoneDateFormat(utcTimestamp) {
	const nztimezone = 'Pacific/Auckland';
	const utcDatetime = new Date(utcTimestamp * 1000);
	const formattedDate = dayjs(utcDatetime).tz(nztimezone).format('MMM DD, YYYY');
	return formattedDate;
}

export { UTCdateToFormattedDate, TimestampToDate, UTCdateWithMillisecondToDate, TimestampToFormattedDate, formatDateToCustomString, utcTimestampToNZTimezone, utcTimestampToNZTimezoneDateFormat };