import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { updatePass } from '../../services/PassesService';
import { UserContext } from '../../contexts/UserContext';
import { showConfirmationAlert, showDefaultAlert } from '../../utils/alerts';
import { utcTimestampToNZTimezone } from '../../utils/format';
import ReactDatePicker from 'react-datepicker';

function PassModel({ closeModel, selectedRow, updatedPass }) {

  const { token } = useContext(UserContext);

  const limits = [selectedRow.AvailablePasses + 1, selectedRow.AvailablePasses - 1];

  const [show, setShow] = useState(false);
  const [availablePasses, setAvailablePasses] = useState(selectedRow.AvailablePasses);
  const [usedPasses, setUsedPasses] = useState(selectedRow.UsedPasses);
  const [expiredAt, setExpiredAt] = useState(new Date(utcTimestampToNZTimezone(selectedRow.ExpiresAt)));
  const [newExpiredAt, setNewExpiredAt] = useState(selectedRow.ExpiresAt);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  useEffect(() => {
    setShow(true);
  }, []);

  const handlevAvailablePassesDecrement = () => {

    if (availablePasses > 0) {
      if (availablePasses > limits[1]) {
        setAvailablePasses(availablePasses - 1);
        setUsedPasses(usedPasses + 1);
        setIsFormChanged(true);
        setError(null);
      }
      else {
        setError("You can only perform one decrement per day");
      }
    }
    else {
      setError("Cannot exceed the limit");
    }
  };

  const handleAvailablePassesIncrement = () => {

    if (availablePasses < 5 && usedPasses > 0) {
      if (availablePasses < limits[0]) {
        setAvailablePasses(availablePasses + 1);
        setUsedPasses(usedPasses - 1);
        setIsFormChanged(true);
        setError(null);

      }
      else {
        setError("You can only perform one increment per day");
      }
    }
    else {
      setError("Cannot exceed the limit");
    }
  };

  const handleDateChange = (date) => {
    setExpiredAt(date);
    setNewExpiredAt(date.getTime() / 1000);
    setIsFormChanged(true);
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    const confirmationMessage = 'Are you sure you want to update pass?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        setIsSubmitting(true);
        try {
          const response = await updatePass(selectedRow.UserID, selectedRow.CreatedAt, availablePasses, usedPasses, selectedRow.Status, newExpiredAt, token);
          showDefaultAlert('success', 'Success', 'Successfully updated the passess.');
          updatedPass(response.data);
          return response;

        } catch (error) {
          setIsSubmitting(false);
          if (error.response && error.response.status === 429) {
            if (error.response.data === 'Exceeded daily update limit') {
              showDefaultAlert('error', 'Exceeded Limit', 'Exceeded daily update limit');
            }
            if (error.response.data === 'Pass purchased by user today') {
              showDefaultAlert('error', "Can't update", 'Pass purchased by user today');
            }
          } else {
            showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          }
        } finally {
          handleClose();
        }
      },
      onCancel: () => {
        handleClose();
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title style={{ marginLeft: '20px' }}>Update Pass</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: 'auto' }}>
          <Form>
            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black', width: '150px' }}>Available Passes</Form.Label>
              <div className="d-flex">
                <Button variant="btn btn-danger" onClick={() => handlevAvailablePassesDecrement()}>
                  -
                </Button>
                <Form.Control
                  type="number"
                  selected={availablePasses}
                  value={availablePasses}
                />
                <Button variant="btn btn-danger" onClick={() => handleAvailablePassesIncrement()} >
                  +
                </Button>
              </div>
            </Form.Group>
            <div>
              {error && <div style={{ color: "red", fontSize: "12px", marginTop: "8px" }}>{error}</div>}
            </div>
            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ color: 'black', width: 'auto' }}>Used Passes</Form.Label>
              <Form.Control
                type="number"
                value={usedPasses}
                style={{ width: "auto" }}
                onChange={() => {
                  handleAvailablePassesIncrement();
                }} />
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput3">
              <Form.Label style={{ color: 'black' }}>Claim Validity</Form.Label>
              <Form.Control
                type="email"
                style={{ width: 'auto' }}
                disabled
                defaultValue={`${selectedRow.ClaimValidity} days`}
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput6">
              <Form.Label style={{ color: 'black', width: '150px' }}>Expire Date</Form.Label>
              <div className="d-flex">
                <ReactDatePicker
                  selected={expiredAt}
                  onChange={(e) => {
                    handleDateChange(e);
                  }}
                  dateFormat="MMMM d, yyyy"
                  className="custom-datepicker-input"
                  minDate={new Date(expiredAt)}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-secondary" style={{ width: 'auto' }} onClick={handleClose}>
            Close
          </Button>
          <Button variant="btn btn-primary" style={{ width: 'auto' }} onClick={handleSubmit} disabled={!isFormChanged || isSubmitting}>
            Update Pass
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default PassModel;