import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addFlagAttribute } from '../../services/APIService';
import { UserContext } from '../../contexts/UserContext';
import { showDefaultAlert, showConfirmationAlert } from '../../utils/alerts';

function InvokeAPIModel({ closeModel, claim }) {

  const { token } = useContext(UserContext);


  const [show, setShow] = useState(false);
  const [vehicleAPIChecked, setVehicleAPIChecked] = useState(false);
  const [moneyAPIChecked, setMoneyAPIChecked] = useState(false);

  const handleClose = () => {
    setShow(false);
    closeModel(false);
  };

  useEffect(() => {
    setShow(true);
  }, []);

  const handleSubmit = async () => {
    const confirmationMessage = 'Are you sure you want to invoke the selected APIs?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await addFlagAttribute(
            claim.VIN,
            vehicleAPIChecked,
            moneyAPIChecked,
            claim.ClaimID,
            token
          );
          showDefaultAlert('success', 'Success', 'The selected APIs has been successfully added.');
          return response;

        } catch (error) {
          if (error.response && error.response.status === 400) {
            if (error.response.data === 'Inquire vehicle details already added') {
              showDefaultAlert('error', 'Already Added', 'Inquire vehicle details are already added');
            }
            else if (error.response.data === "Money owing check already added") {
              showDefaultAlert('error', 'Already Added', 'Money owing check details are already added');
            }
            else if (error.response.data === 'Both APIs are already added') {
              showDefaultAlert('error', 'Already Added', 'Both APIs are already added');
            }
          } else {
            showDefaultAlert('error', 'Error', 'An error occurred. Please try again later.');
          }
        } finally {
          handleClose();
        }
      },
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>Invoke API</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: 'auto' }}>
          <div>
            <label style={{ color: "black" }}>
              <input
                type="checkbox"
                name="option1"
                onChange={() => setVehicleAPIChecked(true)}
              />
              Invoke inquire vehicle details API
            </label>
          </div>
          <div>
            <label style={{ color: "black" }}>
              <input
                type="checkbox"
                name="option2"
                onChange={() => setMoneyAPIChecked(true)}
              />
              Invoke money owing check API
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InvokeAPIModel;