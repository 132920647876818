import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { utcTimestampToNZTimezone } from "../utils/format";
import SpinnerA from "../components/Spinner";
import { UserContext } from '../contexts/UserContext';
import { deleteFeaturedVehicle, getAllFeaturedVehicles } from "../services/VehicleService";
import FeaturedVehicleModel from "../components/FeaturedVehicleModel";
import { showConfirmationAlert, showDefaultAlert } from "../utils/alerts";

const FeaturedVehicles = () => {

  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [featuredVehicleData, setFeaturedVehicleData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showFeaturedVehicleModel, setShowFeaturedVehicleModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [VIN, setVIN] = useState("");
  const [updatedData, setUpdatedData] = useState([]);
  const [mode, setMode] = useState("");


  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;
  const currentPageItems = featuredVehicleData?.slice(offset, offset + itemsPerPage);

  const sortedData = (data) => {
    return data.sort((a, b) => {
      if (a.Status === 'Active') return -1;
      if (b.Status === 'Active') return 1;
      return 0;
    });
  };

  const handleUpdateModel = (featuredVehicle) => {
    setShowFeaturedVehicleModel(true);
    setVIN(featuredVehicle.VIN);
    setMode("update");
    setSelectedRow(featuredVehicle);
  };

  const handleAddModel = () => {
    setShowFeaturedVehicleModel(true);
    setMode('add');
  };

  useEffect(() => {
    const setUpdateFeaturedVehicle = (updatedFeaturedVehicle) => {
      setFeaturedVehicleData((prevFeaturedVehicleData) => {
        return sortedData(prevFeaturedVehicleData.map((featuredVehicle) => {
          if (featuredVehicle.VIN === updatedFeaturedVehicle.VIN) {
            return updatedFeaturedVehicle;
          }
          return featuredVehicle;
        }));
      });
    };
    setUpdateFeaturedVehicle(updatedData);
  }, [updatedData]);

  useEffect(() => {
    setLoading(true);
    const fetchVehicleRecords = async () => {
      try {
        const response = await getAllFeaturedVehicles(token);
        if (response.status === 200) {
          setFeaturedVehicleData(sortedData(response.data));
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchVehicleRecords();
  }, [token]);

  const handleDelete = async (VIN) => {
    const confirmationMessage = 'Are you sure you want to delete the selected vehicle?';

    showConfirmationAlert({
      confirmationMessage,
      onConfirm: async () => {
        try {
          const response = await deleteFeaturedVehicle(VIN, token);
          if (response.status === 200) {
            showDefaultAlert('success', 'Success', `Successfully Deleted the Featured Vehicle.`);
            setFeaturedVehicleData(featuredVehicleData.filter(vehicle => vehicle.VIN !== VIN));
          }
        }
        catch (error) {
          showDefaultAlert('error', 'Error', `Error Deleting the Featured Vehicle.`);
        }
      }
    });
  };

  return (
    <div style={{ width: "90%", margin: "auto", marginTop: "50px" }}>
      <div>
        <h3 className="claim-topic-color">Featured Vehicles</h3>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: '10px' }}>
        <Button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={() => handleAddModel()}>Add New Featured Vehicle</Button>
      </div>
      {loading ? (<SpinnerA />) : (
        <div>
          <Table responsive="sm" hover>
            <thead>
              <tr>
                <th>VIN</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Position</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {featuredVehicleData.length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center">
                    <h5> No records found.</h5>
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {currentPageItems.map((featuredVehicle) => (
                    <tr
                      key={featuredVehicle.CreatedAt}
                      className={featuredVehicle.Status === 'Active' ? "table-primary" : ''}
                    >
                      <td>{featuredVehicle.VIN}</td>
                      <td>{utcTimestampToNZTimezone(featuredVehicle.StartTime)}</td>
                      <td>{utcTimestampToNZTimezone(featuredVehicle.EndTime)}</td>
                      <td>{featuredVehicle.Position}</td>
                      <td>{featuredVehicle.Status}</td>
                      <td>
                        <Button
                          style={{ width: 'auto', marginInline: '5px' }}
                          className="btn btn-danger"
                          onClick={() => handleUpdateModel(featuredVehicle)}
                        >
                          Update
                        </Button>
                        <Button
                          style={{ width: 'auto', marginInline: '5px' }}
                          className="btn btn-danger"
                          onClick={() => handleDelete(featuredVehicle.VIN)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )}
            </tbody>
          </Table>

          {showFeaturedVehicleModel && <FeaturedVehicleModel closeModel={setShowFeaturedVehicleModel} VIN={VIN} mode={mode} selectedRow={selectedRow} setUpdatedData={setUpdatedData} setAddedData={setFeaturedVehicleData} />}

          {featuredVehicleData && featuredVehicleData.length > itemsPerPage && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(featuredVehicleData.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => setCurrentPage(data.selected)}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousClassName={"pagination-previous"}
              nextClassName={"pagination-next"}
              pageClassName={"pagination-page"}
              breakClassName={"pagination-break"}
              previousLinkClassName={"pagination-previous-link"}
              nextLinkClassName={"pagination-next-link"}
              disabledClassName={"pagination-disabled"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FeaturedVehicles;
