import axios from "axios";

const getAllPromotions = (token) => {
	return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}getPromotion`, {},
		{
			headers:
				{ Authorization: `Bearer ${token}` },
		});
};

const createPromotion = (start, promotionName, end, status, totalPasses, availablePasses, maxPassesPerUser, claimValidity, passValidity, token) => {
	return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}createPromotion`, { start, promotionName, end, status, totalPasses, availablePasses, maxPassesPerUser, claimValidity, passValidity },
		{
			headers:
				{ Authorization: `Bearer ${token}` },
		});
};

const updatePromotion = (promotionID, promotionName, start, end, status, totalPasses, availablePasses, maxPassesPerUser, claimValidity, passValidity, token) => {
	return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}updatePromotion`, { promotionID, promotionName, start, end, status, totalPasses, availablePasses, maxPassesPerUser, claimValidity, passValidity },
		{
			headers:
				{ Authorization: `Bearer ${token}` },
		});
};

export { getAllPromotions, createPromotion, updatePromotion };